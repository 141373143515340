import { call, put } from 'redux-saga/effects';
import {
    setMtto, savedMtto, setMttoHistorial, setIsLoading,
} from '../../ducks/mttos';
import { requestLoadMtto, requestSaveMtto, requestLoadMttoHistorial } from '../requests/mttos';
import { setSnackbar } from '../../ducks/snackbar';

export function* handleLoadMtto(action) {
    try {
        const response = yield call(requestLoadMtto, action.id);
        const { data } = response;
        yield put(setMtto(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveMtto(action) {
    try {
        const response = yield call(requestSaveMtto, action.mtto, action.password);

        const { data } = response;

        yield put(savedMtto(data));
        yield put(setSnackbar(true, 'success', 'Folio de Mantenimiento Guardado exitosamente'));
    } catch (error) {
        console.log(error);
    }
}

export function* handleLoadMttoHistorial() {
    yield put(setIsLoading(true));
    try {
        const response = yield call(requestLoadMttoHistorial);
        const { data } = response;
        yield put(setMttoHistorial(data));
    } catch (error) {
        console.log(error);
    } finally {
        yield put(setIsLoading(false));
    }
}
