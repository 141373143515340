import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

export function useForm(
    initialFieldValues,
    validateOnChange = false,
    validateFunction,
) {
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});

    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };

    const removeField = (fieldName) => {
        const { [fieldName]: removedField, ...remainingValues } = values;

        // Check if 'materiales' exists and is an array
        if (Array.isArray(remainingValues.materiales)) {
            remainingValues.materiales = remainingValues.materiales.map((material) => {
                const { isDirty, ...rest } = material; // Remove isDirty property
                return rest; // Return the modified object without isDirty
            });
        }

        return remainingValues;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
            isDirty: true,
        });
        if (validateOnChange) {
            validateFunction({ [name]: value });
        }
    };
    const handleInputChangeAutocomplete = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value?.id,
            isDirty: true,
        });
        if (validateOnChange) {
            validateFunction({ [name]: value });
        }
    };

    const setAllValues = (newValues) => {
        setValues(newValues);
    };

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputChangeAutocomplete,
        removeField,
        resetForm,
        setAllValues,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1),
        },
    },
}));

const useStylesNoMargin = makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: '3px',
        },
    },
}));

export function Form(props) {
    const classes = useStyles();
    const { children, ...other } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <form autoComplete='off' className={classes.root} {...other}>
            {children}
        </form>
    );
}

export function FormNoMargin(props) {
    const classes = useStylesNoMargin();
    const { children, ...other } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <form autoComplete='off' className={classes.root} {...other}>
            {children}
        </form>
    );
}
