import {
    combineReducers, createStore, applyMiddleware, compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';
import viajesReducer from './ducks/viajes';
import operadoresReducer from './ducks/operadores';
import unidadesReducer from './ducks/unidades';
import facturasReducer from './ducks/facturas';
import updateButtonReducer from './ducks/updateButton';
import casetasButtonReducer from './ducks/casetasButton';
import sideMenuIndexReducer from './ducks/sideMenuIndex';
import gasolinasReducer from './ducks/gasolinas';
import casetasNewReducer from './ducks/casetas_New';
import casetasLiqReducer from './ducks/casetasLiq';
import tramosReducer from './ducks/tramos';
import clientesReducer from './ducks/clientes';
import gpsallReducer from './ducks/gpsall';
import watcherSaga from './sagas/rootSaga';
import snackbarReducer from './ducks/snackbar';
import mttosReducer from './ducks/mttos';
import registroMaterialesReducer from './ducks/registroMateriales';
import dirtyMaterialesReducer from './ducks/dirtyMateriales';

const reducers = combineReducers({
    viajes: viajesReducer,
    snackbar: snackbarReducer,
    operadores: operadoresReducer,
    unidades: unidadesReducer,
    facturas: facturasReducer,
    clientes: clientesReducer,
    gasolinas: gasolinasReducer,
    casetasNew: casetasNewReducer,
    casetasLiq: casetasLiqReducer,
    tramos: tramosReducer,
    gpsall: gpsallReducer,
    updateButton: updateButtonReducer,
    sideMenuIndex: sideMenuIndexReducer,
    casetasButton: casetasButtonReducer,
    mttos: mttosReducer,
    registroMateriales: registroMaterialesReducer,
    dirtyMateriales: dirtyMaterialesReducer,
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [sagaMiddleware];
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)));

sagaMiddleware.run(watcherSaga);

export default store;
