import React from 'react';
import {
    FormControl,
    TextField,
    FormHelperText,
    Autocomplete as MuiAutocomplete,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(0),
        '&. MuiFormControl-root': {
            width: '100%',
            margin: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(0),
            paddingLeft: theme.spacing(0),
        },
    },
}));

export default function Autocomplete(props) {
    const classes = useStyles();
    const {
        name,
        label,
        options,
        onChange,
        value,
        error = null,
        displayField = 'title',
        displayField1 = '',
        disabled = false,
        ...others

    } = props;
    return (
        <FormControl
            sx={{ width: '30%' }}
            variant='outlined'
            {...(error && {
                error: true,
            })}
        >
            <MuiAutocomplete
                // className={classes.root}
                defaultValue={{ text: '', id: 0 }}
                disableClearable={value?.id === ''}
                disabled={disabled}
                getOptionLabel={(option) => option[displayField] + (displayField1 !== '' ? ` ${option[displayField1]}` : '')}
                isOptionEqualToValue={(option, v) => {
                    if (v === undefined) return false; // If no value is selected, return false
                    if (v.id === 0) return { text: '', id: 0 };
                    return option.id === v.id; // Compare option id with the value id
                }}
                name={name}
                onChange={(event, newValue) => {
                    onChange({
                        target: {
                            name,
                            value: newValue,
                        },
                    });
                }}
                options={options}
                renderInput={(params) => (
                    <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        label={`${label}`}
                        variant='outlined'
                    />
                )}
                // renderOption={(_, option) => (
                //     <li key={option.id}>
                //         {option[displayField]}
                //         {' '}
                //         {(displayField1 !== '' ? option[displayField1] : '')}
                //     </li>
                // )}
                sx={{ width: '100%' }}
                value={value || { text: '', id: 0, numeroEconomico: '' }}

            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
