/* eslint-disable */
import {
    Grid, InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    withStyles,
    TableSortLabel,
    Toolbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { Search } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalculateIcon from '@mui/icons-material/Calculate';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import moment from 'moment';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { loadViajes } from '../../redux/ducks/viajes';
import { dateAndTimeToMoment, filterOnAnyField } from '../../utils';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { loadUnidades } from '../../redux/ducks/unidades';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';

function calcularCostoMateriales(mtto) {
    const currentMateriales = mtto.materiales;
    let totalCostoMateriales = 0.00;

    if (currentMateriales) {
        currentMateriales.forEach((m) => {
            totalCostoMateriales += m.cantidad * m.precioUnitario;
        });
    }
    return totalCostoMateriales;
}




const opcionesFacturas = [
    {
        id: '---',
        title: '---',
    },
    {
        id: 'Sin Factura',
        title: 'Sin Factura',
    },
    {
        id: 'Generada',
        title: 'Generada',
    },
    {
        id: 'Enviada',
        title: 'Enviada',
    },
    {
        id: 'Pagada',
        title: 'Pagada',
    },
];


const headCells = [
    { id: '', label: '' },
    { id: 'id', label: 'Folio' },
    { id: 'unidad', label: 'Unidad' },
    { id: 'mecanico', label: 'Mecánico' },
    { id: 'kilometraje', label: 'Kilometraje' },
    { id: 'fecha', label: 'Fecha' },
    { id: 'tipo', label: 'Tipo' },
    { id: 'descripcionRep', label: 'Descripción Reparacion' },
    { id: 'status', label: 'Status' },
    { id: 'operador', label: 'Operador' },
    { id: 'costoMateriales', label: 'Costo Total Materiales' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

const headCellsMateriales = [
    { id: 'numeroParte', label: 'Numero de Parte' },
    { id: 'nombreMaterial', label: 'Nombre del Material' },
    { id: 'proveedor', label: 'Proveedor' },
    { id: 'precioUnitario', label: 'Precio Unitario' },
    { id: 'cantidad', label: 'Cantidad' },
    { id: 'total', label: 'Total' },
    { id: 'facturaProveedor', label: 'Factura' },
    { id: 'fechaCompra', label: 'Fecha de Compra' },    
    { id: 'concepto', label: 'Concepto' },

];

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '450',
        },
        '& tbody tr:hover': {
        // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    sinFactura: {
        background: '#F5B7B1',
        lineHeight: 1,
    },
    facturaGenerada: {
        background: '#FCF3CF',
        lineHeight: 1,
    },
    facturaEnviada: {
        background: '#AED6F1',
        lineHeight: 1,
    },
    facturaPagada: {
        background: '#ABEBC6',
        lineHeight: 1,
    },
    colorBase: {
        background: '#ffffff',
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    amarillo: { backgroundColor: '#f5f5dc' },

}));

function Row({ item, orderBy }) {
    const [open, setOpen] = React.useState(false);
    const [order, setOrder] = useState();
    const classes = useStyles();

    const getHeaderTramoStyle = (tramos) => {
        const c = classes.facturaPagada;
        const facturas = tramos.map((t) => t.factura);

        if (facturas.includes(null)) {
            const pagoConEfectivo = tramos.filter((t) => !t.factura && t.pagoEfectivo);
            if (tramos.length === pagoConEfectivo.length) {
                return classes.facturaPagada;
            }

            return classes.sinFactura;
        }
        const status = facturas.map((s) => s.status);

        if (status.includes('Generada')) {
            return classes.facturaGenerada;
        }
        if (status.includes('Enviada')) {
            return classes.facturaEnviada;
        }

        if (status.includes('Pagada')) {
            return classes.facturaPagada;
        }

        return classes.sinTramos;
    };
    const getBackgroundStyle = (tramo) => {
        if (!tramo.factura) {
            if (tramo.pagoEfectivo) {
                return classes.facturaPagada;
            }

            return classes.sinFactura;
        }
        switch (tramo.factura.status) {
        case 'Generada':
            return classes.facturaGenerada;
        case 'Enviada':
            return classes.facturaEnviada;
        case 'Pagada':
            return classes.facturaPagada;
        default:
            return classes.facturaPagada;
        }
    };
    // className={getHeaderTramoStyle(item.tramos)}

    return (
        <>
            <TableRow key={item.id} className={classes.colorBase}>
                <TableCell>
                    <IconButton aria-label='expand item' onClick={() => setOpen(!open)} size='small'>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {item.id}
                </TableCell>
                <TableCell>
                    {item.unidad.numeroEconomico}
                </TableCell>
                <TableCell>{item.mecanico}</TableCell>
                <TableCell>
                    {item.kilometraje}
                </TableCell>
                <TableCell>
                    <Moment date={item.fecha} format='D MMM YYYY' />
                </TableCell>
                <TableCell>
                    {item.tipo}
                </TableCell>
                <TableCell>
                    {item.descripcionRep}
                </TableCell>
                <TableCell>
                    {item.status}
                </TableCell>
                <TableCell>
                    {item.operador?.nombre}
                    {' '}
                    {item.operador?.apellidos}
                    {' '}
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={calcularCostoMateriales(item)}
                    />
                </TableCell>

                <TableCell>
                    <Link to={`/Mantenimiento/${item.id}`}>
                        <Controls.ActionButton
                            color='primary'
                        >
                            <EditOutlinedIcon fontSize='small' />
                        </Controls.ActionButton>
                    </Link>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Materiales:
                            </Typography>
                            <Table aria-label='purchases' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headCellsMateriales.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.materiales.map((material) => (
                                        <TableRow
                                            key={material.id}
                                            // className={getBackgroundStyle(material)}
                                        >
                                            <TableCell component='th' display='block' scope='row'>{material.numeroParte}</TableCell>
                                            <TableCell>{material.nombreMaterial}</TableCell>
                                            <TableCell>{material.proveedor}</TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix='$'
                                                    thousandSeparator
                                                    value={material.precioUnitario}
                                                />
                                            </TableCell>
                                            <TableCell>{material.cantidad}</TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix='$'
                                                    thousandSeparator
                                                    value={material.cantidad * material.precioUnitario}
                                                />
                                            </TableCell>
                                            <TableCell>{material.facturaProveedor}</TableCell>
                                            <TableCell><Moment date={material.fechaCompra} /></TableCell>                                            
                                            <TableCell>{material.concepto}</TableCell>

 

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
export default function ReporteMtto() {
    const dispatch = useDispatch();
    const unidades = useSelector((state) => state.unidades.unidades);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [mttoFiltered, setMttoFiltered] = useState(null);

    const [isLoading, setIsLoading] = useState(false);


    const [folio, setFolio] = useState();
    const handleChangeFolio = (e) => {
        setFolio(e.target.value);
    };

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [unidadAutocomplete, setUnidadAutocomplete] = useState(undefined);
    const [numeroParte, setNumeroParte] = useState('');
    const [nombreMaterial, setNombreMaterial] = useState('');

    async function calculateMttos() {
        if (!startDate || !endDate) {
            setSnackbarMessage('Para generar reporte necesitas indicar AL MENOS fecha de inicio y fecha fin');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        let filters = '';

        const fechaInicio = moment(startDate).format('YYYY-MM-DD');
        const fechaFin = moment(endDate).format('YYYY-MM-DD');


        filters = "fechaInicio="+fechaInicio+"T00:00:00&fechaFin="+fechaFin+"T23:59:59";

        if (numeroParte !== '') {
            filters = `${filters}&numeroParte=${numeroParte}`;
        }

        if (unidadAutocomplete) {
            filters = `${filters}&unidad=${unidadAutocomplete.numeroEconomico}`;
        }

        if (nombreMaterial !== '') {
            filters = `${filters}&nombreMaterial=${nombreMaterial}`;
        }

        setIsLoading(true);
        try {
            const response = await http.get(`/mtto/filter?${filters}`);
            if (response) {
                setMttoFiltered(response.data);
            }
        } catch (error) {
            console.error(error);
            setSnackbarMessage('Error al obtener los datos');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    }

    async function calculateMttosByFolio() {
        if (!folio) {
            setSnackbarMessage('Para generar reporte necesitas indicar un folio');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        setIsLoading(true);
        try {
            const response = await http.get(`/mtto/${folio}`);
            if (response) {
                setMttoFiltered([response.data]);
            }
        } catch (error) {
            console.error(error);
            setSnackbarMessage('Error al obtener los datos');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    }

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(mttoFiltered, headCells, filterFn);
    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };

    const refreshData = () => {
        dispatch(loadViajes());
    };
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    const handleSortRequest = (cellId) => {
        const isAsc = orderBy === cellId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(cellId);
    };

    useEffect(() => {
        dispatch(setIndex(3, 'Taller'));
        dispatch(loadUnidades());
    }, [dispatch]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <Box component='span' displayPrint='none'>
                <PageHeader
                    displayPrint='none'
                    icon={<AssessmentIcon fontSize='large' />}
                    subtitle=''
                    title='Reporte de Mantenimientos'
                />
                <Paper name='filterPaper' className={classes.filterPaper}>
                    <Toolbar>
                        <Controls.DatePicker
                            label='Fecha de Compra INICIO *'
                            name='fechaCompraInicio'
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                        />
                        <Controls.DatePicker
                            label='Fecha de Compra FIN *'
                            name='fechaCompraFin'
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                        />
                        <Controls.Button
                            className={classes.searchInput}
                            name='generarReporte'
                            onClick={() => calculateMttos()}
                            startIcon={<CalculateIcon />}
                            style={{ marginLeft: '10px' }}
                            text='Generar Reporte'
                        />
                        <Controls.Button
                            className={classes.searchInput}
                            startIcon={<NotInterestedIcon />}
                            style={{ marginLeft: '10px' }}
                            text='Limpiar Filtro'
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                                setNumeroParte('');
                                setNombreMaterial('');
                                setUnidadAutocomplete(undefined);
                                setMttoFiltered(null);
                            }}
                        />
                    </Toolbar>
                    <Toolbar>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label='Numero de Parte'
                                    name='numeroParte'
                                    onChange={(e) => { setNumeroParte(e.target.value); }}
                                    style={{ width: '100%' }}
                                    value={numeroParte}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controls.Input
                                    label='Nombre del Material'
                                    name='nombreMaterial'
                                    onChange={(e) => { setNombreMaterial(e.target.value); }}
                                    style={{ width: '100%' }}
                                    value={nombreMaterial}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controls.AutocompleteWider
                                    displayField='numeroEconomico'
                                    label='Unidad'
                                    name='unidad'
                                    onChange={(e) => { setUnidadAutocomplete(e.target.value); }}
                                    options={unidades}
                                    value={unidadAutocomplete}

                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Paper>
                <Paper className={classes.filterPaper} style={{ marginTop: '20px' }}>
                    <Toolbar>
                        <Controls.Input
                            label='Folio'
                            name='folio'
                            onChange={(e) => setFolio(e.target.value)}
                            style={{ width: '200px' }}
                            value={folio}
                            type="number"
                            inputProps={{ 
                                min: "0",
                                onKeyPress: (event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }
                            }}
                        />
                        <Controls.Button
                            name='generarPorFolio'
                            onClick={() => calculateMttosByFolio()}
                            startIcon={<CalculateIcon />}
                            style={{ marginLeft: '10px', width: '300px' }}
                            text='Generar Por Folio'
                            size="large"
                        />
                    </Toolbar>
                </Paper>

                <Grid
                    container
                    justifyContent='center'
                    paddingTop='.5em'

                />

                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={12} style={{ marginTop: '15px' }}>

                        <Grid
                            alignContent='space-around'
                            container
                            justifyContent='center'
                            spacing={1}
                        >

                            <Grid item xs={8}>
                                <Controls.Input
                                    className={classes.searchInput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='end'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label='Buscar'
                                    onChange={handleSearch}
                                    value={filter}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Box>
            <TblContainer component={Paper}>
                <TblHead>
                    <TableRow>
                        <TableCell />
                        {headCells.map((head) => (

                            <TableCell
                                key={head.id}
                                sortDirection={orderBy === head.id ? order : false}
                            >
                                {head.disableSorting ? (head.label) : (
                                    <TableSortLabel
                                        active={orderBy === head.id}
                                        direction={
                                            orderBy === head.id ? order : 'asc'
                                        }
                                        onClick={() => handleSortRequest(head.id)}
                                    >
                                        {head.label}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TblHead>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={headCells.length} align="center">
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 6 }}>
                                    <CircularProgress />
                                    <Typography variant='h6' sx={{ mt: 2 }}>Cargando...</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        recordsAfterPagingAndSorting().map((item) => (
                            <Row key={item.id} item={item} />
                        ))
                    )}
                </TableBody>
            </TblContainer>
            <Box component='span' displayPrint='none'>
                <TblPagination />
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
