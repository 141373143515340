import React from 'react';
import {
    Paper, Card, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fdfdff',
    },
    pageHeader: {
        padding: theme.spacing(4),
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    pageIcon: {
        // display: 'inline-block',
        padding: theme.spacing(2),
        color: '#3c44b1',
    },
    pageTitle: {
        paddingLeft: theme.spacing(4),
        '& .MuiTypography-subtitle2': {
            opacity: '0.6',
        },
    },
}));

export default function PageHeader(props) {
    const classes = useStyles();
    const { title, subTitle, icon } = props;
    return (
        <Paper className={classes.root} elevation={0} square>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>{icon}</Card>
                <div className={classes.pageTitle}>
                    <Typography component='div' variant='h6'>
                        {title}
                    </Typography>
                    <Typography component='div' variant='subtitle2'>
                        {subTitle}
                    </Typography>
                </div>
            </div>
        </Paper>
    );
}
