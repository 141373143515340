const tipoPagoBanco = [
    { id: 'EFECTIVO', text: 'Efectivo' },
    { id: 'TARJETA_CREDITO', text: 'Tarjeta de Credito' },
    { id: 'TRANSFERENCIA', text: 'Transferencia' },
];

export default function getTipoPagoBanco(id) {
    if (id) {
        return tipoPagoBanco.find((item) => item.id === id) || null;
    }
    return tipoPagoBanco;
}
