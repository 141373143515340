import React, { useState, useEffect } from 'react';
import {
    Paper,
    TableBody,
    TableRow,
    TableCell,
    Toolbar,
    InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForever, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import PasswordIcon from '@mui/icons-material/Password';
import SaveIcon from '@mui/icons-material/Save';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import { loadUnidades, saveUnidad } from '../../redux/ducks/unidades';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { filterOnAnyField } from '../../utils';
import ConfirmDialog from '../../components/ConfirmDialog';
import http from '../../http-common';
import getTipoDePassword from '../../services/tipoDePassword';
import { setSnackbar } from '../../redux/ducks/snackbar';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '40%',
    },
    newButton: {},
    refreshButton: {},
}));

export default function Password() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const unidades = useSelector((state) => state.unidades.unidades);
    const indexInfo = useSelector((state) => state.sideMenuIndex);
    const [tipoPassword, setTipoPassword] = useState({ id: '', text: '' });
    const [nuevoPassword, setNuevoPassword] = useState('');
    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                // var itemsNumeroEco =  items.filter( x =>  x.numeroEconomico.toLowerCase().includes(target.value.toLowerCase()));
                // var itemsCapacidad = items.filter( x =>  x.capacidadCarga.toLowerCase().includes(target.value.toLowerCase()));
                // var totalFilters = itemsCapacidad; //+ itemsNumeroEco;
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };
    const dispatch = useDispatch();

    const addOrEdit = (unidad, resetForm) => {
        dispatch(saveUnidad(unidad));
        setOpenPopup(false);
        resetForm();
    };

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(3, 'Op'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteUnidad = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar la unidad?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/unidades/${item.id}`);
                dispatch(loadUnidades());
            },
        });
    };

    const clearValues = () => {
        setTipoPassword({ id: '', text: '' });
        setNuevoPassword('');
    };

    const savePassword = async () => {
        try {
            const response = await http.post('/GenericParameters/savePassword', {
                parameterName: 'PasswordParaAprobar',
                attributeSeqno: 1,
                attributeName: tipoPassword.id,
                attributeValue: nuevoPassword,
            });
            if (response.status === 200) { // Check if the response is successful
                dispatch(setSnackbar(true, 'success', 'Password guardado correctamente'));
                clearValues();
            } else {
                dispatch(setSnackbar(true, 'error', 'Error al guardar el password'));
            }
        } catch (error) {
            dispatch(setSnackbar(true, 'error', 'Error al guardar el password'));
        }
    };

    return (
        <>
            <PageHeader
                icon={<PasswordIcon fontSize='large' />}
                subtitle=''
                title='Cambiar Contraseña para aprobar viajes/mantenimientos'
            />
            <Paper className={classes.pageContent}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <Controls.AutocompleteWider
                        displayField='text'
                        label='Tipo de Password *'
                        name='tipoPassword'
                        onChange={(e) => setTipoPassword(e.target.value)}
                        options={getTipoDePassword()}
                        value={tipoPassword}
                    />
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <PasswordIcon fontSize='large' sx={{ paddingRight: '10px' }} />
                                </InputAdornment>
                            ),
                        }}
                        label='Nuevo password'
                        onChange={(e) => setNuevoPassword(e.target.value)}
                        value={nuevoPassword}
                    />
                    <Controls.Button
                        className={classes.searchInput}
                        onClick={() => savePassword()}
                        startIcon={<SaveIcon />}
                        text='Guardar'
                    />
                </div>
            </Paper>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />

        </>
    );
}
