import {
    Grid, Typography, Tooltip, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Checkbox,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import getStatusCompra from '../../services/statusCompra';
import getTipoPagoBanco from '../../services/tipoDePagoBanco';
import { loadUnidades } from '../../redux/ducks/unidades';

const initialFieldValues = {
    id: 0,
    numeroParte: '',
    nombreMaterial: '',
    proveedor: '',
    precioUnitario: 0,
    facturaProveedor: '',
    statusCompra: '',
    fechaPago: '',
    fechaCompra: '',
    cantidad: 0,
    concepto: '',
    banco: '',
    comentarios: '',
    unidad: null,
    diasCredito: null,
    pagarAntesDe: '',
    status: null,
    tipoGasto: '',
    indicadorFactura: false,
};

export default function RegistroMaterialForm(props) {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleInputChangeAutocomplete,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);
    const unidades = useSelector((state) => state.unidades.unidades);
    const errorSavingFlag = useSelector((state) => state.registroMateriales.errorSavingFlag);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('nombreMaterial' in fieldValues) {
            temp.nombreMaterial = fieldValues.nombreMaterial ? '' : 'Campo Requerido';
        }
        if ('proveedor' in fieldValues) {
            temp.proveedor = fieldValues.proveedor ? '' : 'Campo Requerido';
        }
        if ('precioUnitario' in fieldValues) {
            temp.precioUnitario = fieldValues.precioUnitario > 0 ? '' : 'Campo Requerido';
        }
        if ('statusCompra' in fieldValues) {
            temp.statusCompra = fieldValues.statusCompra ? '' : 'Campo Requerido';
        }

        if ('fechaCompra' in fieldValues) {
            temp.fechaCompra = fieldValues.fechaCompra ? '' : 'Campo Requerido';
        }

        if ('concepto' in fieldValues) {
            temp.concepto = fieldValues.concepto ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit, setOpenPopup } = props;
    const [remainOpen, setRemainOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isMultipleUnits, setIsMultipleUnits] = useState(false);
    const [oficinaCount, setOficinaCount] = useState(0);

    const handleApprove = async () => {
        // eslint-disable-next-line
        const password = prompt('Please enter your password:');
        if (password) {
            try {
                // eslint-disable-next-line
                await props.addOrEdit(values, true, password);

                console.log('errorSavingFlag', errorSavingFlag);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setErrorMessage('Password incorrecto');
                }
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (values.tipoGasto === 'UNIDAD' && !isMultipleUnits) {
            if (!values.unidad) {
                setErrorMessage('El tipo de compra es UNIDAD, entonces numero de unidad es requerido, o seleccione VARIAS UNIDADES');
                return;
            }
        }

        if (values.indicadorFactura && !values.facturaProveedor) {
            setErrorMessage('Indicador de Factura esta chequeado, favor de incluir Numero de Factura');
            return;
        }

        let currentValues = { ...values };
        if (currentValues.tipoGasto === '' || currentValues.tipoGasto === null) {
            currentValues = {
                ...currentValues,
                tipoGasto: 'OFICINA',
            };
            setValues(currentValues);
        }

        if (validate()) {
            if (currentValues.status === 'PENDIENTE' || currentValues.status === '' || currentValues.status === null) {
                addOrEdit(currentValues, false, null);
            } else {
                handleApprove();
            }
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    useEffect(() => {
        if (values.tipoGasto === 'UNIDAD' && values.unidad === null && oficinaCount === 0) {
            console.log('si entro');
            setIsMultipleUnits(true);
        }
    }, [values.tipoGasto, values.unidad, oficinaCount]);

    useEffect(() => {
        if (errorSavingFlag === 2) {
            setOpenPopup(false);
        }
        // eslint-disable-next-line
    }, [errorSavingFlag]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && values.diasCredito) {
            const today = new Date();
            const newDate = new Date(today.setDate(today.getDate() + parseInt(values.diasCredito, 10)));
            setValues((prevValues) => ({
                ...prevValues,
                pagarAntesDe: newDate.toISOString().split('T')[0], // Format date to YYYY-MM-DD
            }));
        }
    };

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setValues((prevValues) => ({
            ...prevValues,
            tipoGasto: value,
            unidad: value === 'UNIDAD' ? prevValues.unidad : null, // Clear unidad if Oficina is selected
        }));
        if (value === 'OFICINA') {
            setIsMultipleUnits(false);
        }
        setOficinaCount(oficinaCount + 1);
    };

    const handleCheckboxChange = (event) => {
        setIsMultipleUnits(event.target.checked);
        if (event.target.checked) {
            setValues((prevValues) => ({
                ...prevValues,
                unidad: null,
            }));
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>

                    <Controls.Input
                        error={errors.concepto}
                        label='Concepto *'
                        name='concepto'
                        onChange={handleInputChange}
                        value={values.concepto}
                    />

                    <Controls.Input
                        error={errors.nombreMaterial}
                        label='Nombre del Material *'
                        name='nombreMaterial'
                        onChange={handleInputChange}
                        value={values.nombreMaterial}
                    />
                    <Controls.Input
                        error={errors.proveedor}
                        label='Proveedor *'
                        name='proveedor'
                        onChange={handleInputChange}
                        value={values.proveedor}
                    />
                    <Controls.NumericInput
                        error={errors.precioUnitario}
                        inputProps={{
                            min: '0',
                            step: '0.01',
                            onKeyPress: (event) => {
                                if (!/[0-9.]/.test(event.key) || (event.key === '.' && event.currentTarget.value.includes('.'))) {
                                    event.preventDefault();
                                }
                            },
                            onPaste: (event) => {
                                const pastedData = event.clipboardData.getData('text');
                                if (!/^\d*\.?\d*$/.test(pastedData)) {
                                    event.preventDefault();
                                }
                            },
                        }}
                        label='Total'
                        name='precioUnitario'
                        onChange={handleInputChange}
                        type='number'
                        value={values.precioUnitario}
                    />
                    <Grid>
                        <Tooltip
                            arrow
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        fontSize: '1.0rem', // Increase font size
                                        padding: '10px', // Increase padding
                                    },
                                },
                            }}
                            title='Introduce un numero y presiona ENTER para generar fecha limite para pagar'
                        >
                            <Controls.NumericInput
                                error={errors.diasCredito}
                                inputProps={{
                                    min: '0',
                                    step: '1',
                                    onKeyPress: (event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    },
                                    onPaste: (event) => {
                                        if (!/[0-9]/.test(event.clipboardData.getData('text'))) {
                                            event.preventDefault();
                                        }
                                    },
                                }}
                                label='Días de Crédito'
                                name='diasCredito'
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                type='number'
                                value={values.diasCredito}
                            />
                        </Tooltip>
                    </Grid>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Tipo de Compra</FormLabel>
                        <RadioGroup
                            name='tipoGasto'
                            onChange={(e) => { handleRadioChange(e); }}
                            row
                            value={values.tipoGasto}
                        >
                            <FormControlLabel control={<Radio />} label='Unidad' value='UNIDAD' />
                            <FormControlLabel control={<Radio />} label='Oficina' value='OFICINA' />
                        </RadioGroup>
                    </FormControl>
                    {values.tipoGasto === 'UNIDAD' && (
                        <Grid alignItems='center' container spacing={0}>
                            <Grid item xs={6}>
                                <Controls.AutocompleteWider
                                    disabled={isMultipleUnits}
                                    displayField='numeroEconomico'
                                    error={errors?.unidad}
                                    label='Unidad'
                                    name='unidad'
                                    onChange={(e) => { handleInputChange(e); }}
                                    options={unidades}
                                    value={values?.unidad}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked={isMultipleUnits} onChange={handleCheckboxChange} />}
                                    label='Varias Unidades'
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid style={{ paddingRight: '40px' }}>
                        <Typography variant='h6'>
                            Comentarios
                        </Typography>
                        <Controls.Textarea
                            error={errors?.comentarios}
                            minRows='5'
                            name='comentarios'
                            onChange={(e) => { handleInputChange(e); }}
                            value={values.comentarios}
                        />
                    </Grid>

                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={values.indicadorFactura}
                                name='indicadorFactura'
                                onChange={(e) => setValues({ ...values, indicadorFactura: e.target.checked })}
                            />
                        )}
                        label='Factura'
                    />

                    {values.indicadorFactura && (
                        <Controls.Input
                            error={errors.facturaProveedor}
                            label='Numero de Factura'
                            name='facturaProveedor'
                            onChange={handleInputChange}
                            value={values.facturaProveedor}
                        />
                    )}
                    <Controls.AutocompleteWider
                        disableClearable
                        displayField='text'
                        error={errors?.statusCompra}
                        label='Estatus de Compra *'
                        name='statusCompra'
                        onChange={(e) => { handleInputChangeAutocomplete(e); }}
                        options={getStatusCompra()}
                        value={values?.statusCompra ? getStatusCompra(values.statusCompra) : { id: 0, text: '' }}
                    />
                    <Controls.DatePicker
                        error={errors.fechaPago}
                        label='Fecha de Pago'
                        name='fechaPago'
                        onChange={(e) => { handleInputChange(e); }}
                        value={values.fechaPago}
                    />
                    <Controls.DatePickerNew
                        error={errors.fechaCompra}
                        label='Fecha de Adquisicion'
                        name='fechaCompra'
                        onChange={(e) => { handleInputChange(e); }}
                        value={values.fechaCompra}
                    />
                    <Controls.DatePickerNew
                        error={errors.pagarAntesDe}
                        label='Fecha Limite de Credito'
                        name='pagarAntesDe'
                        onChange={(e) => { handleInputChange(e); }}
                        value={values.pagarAntesDe}
                    />

                    <Controls.Input
                        error={errors.numeroParte}
                        label='Número de Parte'
                        name='numeroParte'
                        onChange={handleInputChange}
                        value={values.numeroParte}
                    />

                    <Controls.AutocompleteWider
                        disableClearable
                        displayField='text'
                        error={errors?.banco}
                        label='Forma de Pago'
                        name='banco'
                        onChange={(e) => { handleInputChangeAutocomplete(e); }}
                        options={getTipoPagoBanco()}
                        value={values?.banco ? getTipoPagoBanco(values.banco) : { id: '', text: '' }}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                        {recordForEdit != null && (
                            <Controls.Button
                                color={values.status === 'APROBADO' ? 'success' : 'primary'}
                                disabled={values.status === 'APROBADO'}
                                name='approve'
                                onClick={handleApprove}
                                style={{ marginLeft: '16px' }}
                                text={values.status === 'APROBADO' ? 'Aprobado' : 'Aprobar'}
                            />
                        )}
                    </div>
                    {errorMessage && <Typography color='error'>{errorMessage}</Typography>}
                </Grid>
            </Grid>
        </Form>
    );
}
