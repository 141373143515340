export const SET_IS_DIRTY_MATERIALES = 'SET_IS_DIRTY_MATERIALES';

export const setIsDirtyMateriales = (flag) => ({
    type: SET_IS_DIRTY_MATERIALES,
    flag,
});

const initialState = {
    isDirtyMateriales: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_IS_DIRTY_MATERIALES:
        return {
            ...state,
            isDirtyMateriales: action.flag,
        };
    default:
        return state;
    }
};
