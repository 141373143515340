import { DownloadForOfflineOutlined, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    InputAdornment, Paper,
    TableBody,
    TableCell,
    TableRow,
    Grid,
    CircularProgress,
    Box,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CsvBuilder } from 'filefy';
import moment from 'moment';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { loadViajes } from '../../redux/ducks/viajes';
import { filterOnAnyField } from '../../utils';
import http from '../../http-common';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    searchPorViaje: {
        width: '30%',
        marginLeft: 23,
    },
    newButton: {},
    refreshButton: {},
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '450',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    sinFactura: {
        background: '#F5B7B1',
        lineHeight: 1,
    },
    facturaGenerada: {
        background: '#FCF3CF',
        lineHeight: 1,
    },
    facturaEnviada: {
        background: '#AED6F1',
        lineHeight: 1,
    },
    facturaPagada: {
        background: '#ABEBC6',
        lineHeight: 1,
    },
    amarillo: { backgroundColor: '#f5f5dc' },

}));

const headCellsTramo = [
    { id: 'viajeId', label: 'Folio' },
    { id: 'TramoId', label: 'Tramo' },
    { id: 'status', label: 'Estatus' },
    { id: 'nombreCliente', label: 'Cliente' },
    { id: 'lugarInicio', label: 'Origen' },
    { id: 'lugarFin', label: 'Destino' },
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'unidad.numeroEconomico', label: 'Unidad' },
    { id: 'nombre', label: 'Operador' },
    { id: 'flete', label: 'Tarifa' },
    { id: 'ordenDeCompra', label: 'Referencia' },
    { id: 'factura', label: 'Factura' },
    { id: 'statusFactura', label: 'Estatus Factura' },
    { id: 'statusPagoOperador', label: 'Status Pago Operador' },
    // { id: 'fechaPago', label: 'Fecha Pago Operador' },
    { id: 'accion', label: 'Acciones', disableSorting: true },
];

// const {handleListItemClick} = useContext(SideMenuContext);

export default function Operators() {
    const classes = useStyles();

    const getBackgroundStyle = (tramo) => {
        if (!tramo.factura) {
            if (tramo.pagoEfectivo) {
                return classes.facturaPagada;
            }

            return classes.sinFactura;
        }

        switch (tramo.statusFactura) {
        case 'Generada':
            return classes.facturaGenerada;
        case 'Enviada':
            return classes.facturaEnviada;
        case 'Pagada':
            return classes.facturaPagada;
        default:
            return classes.facturaPagada;
        }
    };
    const [userInfo, setUserInfo] = useState();
    const { authState, oktaAuth } = useOktaAuth();
    const [canViewViaje, setCanViewViaje] = useState(false);
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((ui) => {
                setUserInfo(ui);
                if (ui.groups.includes('Trafico') || ui.groups.includes('Administradores')) {
                    setCanViewViaje(true);
                }
            });
        }
    }, [authState, oktaAuth]);

    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const viajes = useSelector((state) => state.viajes.viajes);
    const [tramos, setTramos] = useState([]);
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(tramos, headCellsTramo, filterFn);

    const [filter, setFilter] = useState();
    const [filterPorViaje, setFilterPorViaje] = useState();
    const [filterPorUnidad, setFilterPorUnidad] = useState();
    const [fechaInicial, setFechaInicial] = useState();
    const [fechaFinal, setFechaFinal] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                let filteredItems = items.map((m) => m);
                if (target?.value) {
                    filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                }

                if (fechaInicial) {
                    filteredItems = filteredItems.filter((f) => {
                        const fechaTramo = moment(f.fechaInicio);
                        if (fechaTramo.isSameOrAfter(fechaInicial, 'day')) {
                            return true;
                        }

                        return false;
                    });
                }
                if (fechaFinal) {
                    filteredItems = filteredItems.filter((f) => {
                        const fechaTramo = moment(f.fechaInicio);
                        if (fechaTramo.isSameOrBefore(fechaFinal, 'day')) {
                            return true;
                        }
                        return false;
                    });
                }
                return filteredItems;
            },
        });
    };

    const buscarPorFolioChange = (e) => {
        const { target } = e;
        setFilterPorViaje(target.value);
        setFilterPorUnidad(target.value);
    };

    const buscarPorFolio = () => {
        setFilterFn({
            fn: (items) => {
                if (filterPorViaje === '') {
                    return items;
                }
                // eslint-disable-next-line radix
                const filteredItems = items.filter((x) => x.viajeId === parseInt(filterPorViaje));
                console.log(filteredItems);
                return filteredItems;
            },
        });
    };

    const buscarPorUnidad = () => {
        setFilterFn({
            fn: (items) => {
                if (filterPorUnidad === '') {
                    return items;
                }
                // eslint-disable-next-line radix
                const filteredItems = items.filter((x) => x.numeroEconomico === filterPorViaje);
                return filteredItems;
            },
        });
    };

    const mostrarSoloPendientes = () => {
        setFilterFn({
            fn: (items) => {
                // const filteredItems = items.filter((x) => (x.factura !== null ? x.statusFactura !== 'Pagada' : x.factura === null));
                const filteredItems = items.filter((x) => x.factura === null);
                const tramosTotal = filteredItems.filter((x) => !x.pagoEfectivo);

                tramosTotal.sort((a, b) => ((a.fechaInicio > b.fechaInicio) ? 1 : -1));
                return tramosTotal;
            },

        });
    };

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    const refreshData = async () => {
        setIsLoading(true);
        try {
            const data = await http.get('/viajes/historial');
            if (data.data) {
                data.data.sort((a, b) => ((a.fechaInicio < b.fechaInicio) ? 1 : -1));
                setTramos(data.data);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage('Error al obtener los datos');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        refreshData();
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(0, 'Op'));
    }, [dispatch]);

    const handleFechaInicialChange = (v) => {
        setFechaInicial(v.target.value);
    };

    const handleFechaFinalChange = (v) => {
        setFechaFinal(v.target.value);
    };

    useEffect(() => {
        handleSearch({
            target: {
                value: filter,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fechaFinal, fechaInicial]);

    const download = () => {
        const columnas = ['Folio', 'Tramo', 'Estatus', 'Cliente', 'Origen', 'Destino', 'Fecha Inicio', 'Unidad', 'Operador', 'Tarifa', 'Referencia', 'Factura', 'Estatus Factura', 'Tramo Pagado En Efectivo', 'Estatus Pago Operador'];
        const rows = recordsAfterPagingAndSorting().map((tramo) => ([
            tramo.viajeId,
            tramo.id,
            tramo.status,
            tramo.nombreCliente,
            tramo.lugarInicio,
            tramo.lugarFin,
            moment(tramo.fechaInicio).format('yyyy-MM-D'),
            tramo.numeroEconomico,
            tramo.operador,
            tramo.flete,
            tramo.ordenDeCompra,
            tramo.factura,
            tramo.statusFactura,
            tramo.statusPagoOperador,
            tramo.fechaPago,
        ]));
        console.log(rows);
        const builder = new CsvBuilder('viajes.csv');
        builder
            .setDelimeter(',')
            .setColumns(columnas)
            .addRows(rows)
            .exportFile();
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <PageHeader
                icon={<CardTravelOutlinedIcon fontSize='large' />}
                subtitle=''
                title='Historial'
            />
            <Paper className={classes.pageContent}>
                <Controls.Input
                    className={classes.searchPorViaje}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='end'>
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    label='Buscar por Folio'
                    onChange={buscarPorFolioChange}
                    style={{ marginBottom: '10px' }}
                    value={filterPorViaje}
                />
                <Controls.Button
                    className={classes.refreshButton}
                    onClick={() => buscarPorFolio()}
                    text='Buscar por Folio'
                    variant='outlined'
                />
                <Controls.Button
                    className={classes.refreshButton}
                    onClick={() => buscarPorUnidad()}
                    text='Buscar por Unidad'
                    variant='outlined'
                />
                <Controls.Button
                    className={classes.refreshButton}
                    onClick={() => mostrarSoloPendientes()}
                    text='Mostrar solo pendientes'
                    variant='outlined'
                />
                <Grid container>
                    <Grid container style={{ marginBottom: '10px' }}>
                        <Grid item xs={6}>
                            <Controls.Input
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='end'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Busqueda General'
                                onChange={handleSearch}
                                value={filter}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.DatePicker
                                label='Fecha Inicial'
                                name='fechaInicial'
                                onChange={handleFechaInicialChange}
                                value={fechaInicial}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.DatePicker
                                label='Fecha Final'
                                name='fechaFinal'
                                onChange={handleFechaFinalChange}
                                value={fechaFinal}
                            />
                        </Grid>

                    </Grid>
                    <Controls.Button
                        className={classes.refreshButton}
                        onClick={() => refreshData()}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                    />
                    <Link style={{ textDecoration: 'none' }} to='/viaje'>
                        <Controls.Button
                            className={classes.newButton}
                            startIcon={<AddIcon />}
                            text='Agregar Nuevo Viaje'
                            variant='outlined'
                        />
                    </Link>
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            download();
                        }}
                        startIcon={<DownloadForOfflineOutlined />}
                        text='Descargar'
                        variant='outlined'
                    />
                </Grid>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell align='center' colSpan={headCellsTramo.length}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', p: 6,
                                    }}
                                    >
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }} variant='h6'>Cargando...</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            recordsAfterPagingAndSorting().map((tramo) => (
                                <TableRow key={tramo.id} className={getBackgroundStyle(tramo)}>
                                    <TableCell>{tramo.viajeId}</TableCell>
                                    <TableCell>{tramo.id}</TableCell>
                                    <TableCell>{tramo.status}</TableCell>
                                    <TableCell>
                                        {
                                            canViewViaje && tramo.nombreCliente
                                        }
                                    </TableCell>
                                    <TableCell>{tramo.lugarInicio}</TableCell>
                                    <TableCell>{tramo.lugarFin}</TableCell>
                                    <TableCell><Moment date={tramo.fechaInicio} /></TableCell>
                                    <TableCell>{tramo.numeroEconomico}</TableCell>
                                    <TableCell>
                                        {tramo.operador}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            canViewViaje
                                        && (

                                            <NumberFormat
                                                decimalScale={2}
                                                displayType='text'
                                                prefix='$'
                                                thousandSeparator
                                                value={tramo.flete}
                                            />
                                        )
                                        }
                                    </TableCell>
                                    <TableCell>{tramo.ordenDeCompra}</TableCell>
                                    <TableCell>{tramo.factura}</TableCell>
                                    <TableCell>{tramo.statusFactura}</TableCell>
                                    <TableCell>{tramo.statusPagoOperador}</TableCell>

                                    <TableCell>
                                        {
                                            canViewViaje
                                        && (
                                            <Link onClick={() => { dispatch(setIndex(1, 'Op')); }} to={`/viaje/${tramo.viajeId}`}>
                                                <Controls.ActionButton
                                                    color='primary'
                                                >
                                                    <EditOutlinedIcon fontSize='small' />
                                                </Controls.ActionButton>
                                            </Link>
                                        )
                                        }
                                    </TableCell>
                                </TableRow>
                            )))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                open={openSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
