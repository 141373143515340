import {
    DeleteForever, DownloadForOfflineOutlined, Search,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    InputAdornment, Paper,
    TableBody, TableCell, TableRow, Toolbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { CsvBuilder } from 'filefy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { loadFacturas, saveFactura } from '../../redux/ducks/facturas';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { filterOnAnyField } from '../../utils';
import FacturasForm from './FacturasForm';

const columnas = ['Numero De Factura', 'Cliente', 'Fecha', 'Importe', 'Estado', 'Referencia'];

const headCellsTramo = [
    { id: 'id', label: 'Tramo' },
    { id: 'inicio', label: 'Lugar Inicio' },
    { id: 'fin', label: 'Lugar Fin' },
    { id: 'viajeId', label: 'Viaje' },
];

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    searchPorViaje: {
        width: '30%',
        marginLeft: 23,
    },
    newButton: {},
    refreshButton: {},
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
}));

const headCells = [
    { id: 'expand', label: '' },
    { id: 'numeroFactura', label: 'Numero de Factura' },
    { id: 'clienteId', label: 'Cliente' },
    { id: 'fecha', label: 'Fecha' },
    { id: 'importe', label: 'Importe', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { id: 'referenciaFactuea', label: 'Referencia de Factura', disableSorting: true },
    { id: 'acciones', label: '', disableSorting: true },
];

function Row({ item, openInPopup, deleteFactura }) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <>
            <TableRow key={item.id}>
                <TableCell>
                    <IconButton aria-label='expand item' onClick={() => setOpen(!open)} size='small'>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{item.numeroFactura}</TableCell>
                <TableCell>{item.cliente?.razonSocial}</TableCell>
                <TableCell>
                    <Moment date={item.fecha} />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.importe}
                    />
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.referenciaFactura}</TableCell>

                <TableCell>
                    <Controls.ActionButton
                        color='primary'
                        onClick={() => {
                            openInPopup(item);
                        }}
                    >
                        <EditOutlinedIcon fontSize='small' />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                        color='primary'
                        onClick={() => {
                            deleteFactura(item);
                        }}
                    >
                        <DeleteForever fontSize='small' />
                    </Controls.ActionButton>

                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={10} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Tramos Facturados:
                            </Typography>

                            <Table className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headCellsTramo.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.tramos.map((tramo) => (
                                        <TableRow key={tramo.tramoId}>
                                            <TableCell>{tramo.tramoId}</TableCell>
                                            <TableCell>{tramo.lugarInicio}</TableCell>
                                            <TableCell>{tramo.lugarFin}</TableCell>
                                            <TableCell>{tramo.viajeId}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default function Facturas() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const facturas = useSelector((state) => state.facturas.facturas);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(facturas, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };
    const dispatch = useDispatch();

    const addOrEdit = (factura, resetForm) => {
        dispatch(saveFactura(factura));
        setOpenPopup(false);
        resetForm();
    };

    const deleteFactura = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar la factura?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/facturas/${item.id}`);
                dispatch(loadFacturas());
            },
        });
    };

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    useEffect(() => {
        dispatch(loadFacturas());
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadFacturas());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(5, 'Cat'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // "Numero De Factura","Cliente","Fecha","Importe","Estado","Referencia"

    const download = () => {
        const rows = facturas.map((factura) => ([
            factura.numeroFactura,
            factura.cliente?.razonSocial,
            moment(factura.fecha).format('yyyy-MM-D'),
            factura.importe,
            factura.status,
            factura.referenciaFactura,
        ]));
        const builder = new CsvBuilder('facturas.csv');
        builder
            .setDelimeter(',')
            .setColumns(columnas)
            .addRows(rows)
            .exportFile();
    };

    return (
        <Box component='span'>
            <Box component='span' displayPrint='none'>
                <PageHeader
                    icon={<AttachMoneyIcon fontSize='large' />}
                    subtitle=''
                    title='Facturas'
                />

                <Paper className={classes.pageContent} displayPrint='none'>
                    <Toolbar>
                        <Controls.Input
                            className={classes.searchInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='end'>
                                        {' '}
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            label='Buscar'
                            onChange={handleSearch}
                            value={filter}
                        />
                        <Controls.Button
                            className={classes.refreshButton}
                            startIcon={<RefreshIcon />}
                            text='Refrescar'
                            variant='outlined'
                        // onClick={() => dispatch(loadOUnidades())}
                        />
                        <Controls.Button
                            className={classes.newButton}
                            onClick={() => {
                                setRecordForEdit(null);
                                setOpenPopup(true);
                            }}
                            startIcon={<AddIcon />}
                            text='Agregar Nueva'
                            variant='outlined'
                        />

                        <Controls.Button
                            className={classes.newButton}
                            onClick={() => {
                                download();
                            }}
                            startIcon={<DownloadForOfflineOutlined />}
                            text='Descargar'
                            variant='outlined'
                        />

                    </Toolbar>

                </Paper>
            </Box>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                        <Row key={item.id} deleteFactura={deleteFactura} item={item} openInPopup={openInPopup} />
                    ))}
                </TableBody>
            </TblContainer>
            <TblPagination />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Factura'
            >
                <FacturasForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </Box>
    );
}
