import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { Label } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Controls } from '../../components/controls/Controls';
import Popup from '../../components/Popup';
import { Form, useForm } from '../../components/useForm';
import http from '../../http-common';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const origenDestino = [{ id: 'ORIGEN', title: 'Origen' },
    { id: 'DESTINO', title: 'Destino' },
];
const initialFieldValues = {
    id: 0,
    viajeId: '',
    tramoId: null,
    ubicacionId: null,
    eta: null,
    detalles: '',
    ata: null,
    entregaTerminada: null,
    ordenEntrega: 0,
    origenDestino: null,
    fileName: null,
};

export default function UbicacionesViajeForm({
    tramos, addOrEdit, recordForEdit, ubicaciones,
}) {
    const [ubicacionSingle, setubicacionSingle] = useState(null);
    const fetchData2 = async () => {
        const data = await http.get(`/viajes/ubicacionViajeSingle/${recordForEdit.id}`);
        setubicacionSingle(data.data);
    };
    const [openPopup, setOpenPopup] = useState(false);
    const [tempRecord, setTempRecord] = useState([]);

    const classes = useStyles();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('tramoId' in fieldValues) {
            temp.tramoId = fieldValues.tramoId ? '' : 'Campo Requerido';
        }

        if ('ubicacionId' in fieldValues) {
            temp.ubicacionId = fieldValues.ubicacionId ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (values.entregaTerminada && ubicacionSingle.fileName === null) {
            setOpenPopup(true);
        } else if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        const f = async () => {
            await fetchData2();
            if (recordForEdit != null) {
                const ubicacionActual = ubicaciones.find((x) => (x.id === recordForEdit.ubicacionId));
                const recordForEdit2 = { ...recordForEdit, ubicacionId: ubicacionActual };
                setValues({
                    ...recordForEdit2,
                });
            }
        };
        f();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const handleChange = (newValue) => {
        setValues({
            ...values,
            eta: newValue,
        });
    };

    const currentUbicacion = (field) => {
        const showUbicacion = ubicaciones.find((x) => (x.id === recordForEdit.recordForEdit.ubicacionId));

        return showUbicacion.field;
    };

    const handleChangeAta = (newValue) => {
        setValues({
            ...values,
            ata: newValue,
        });
    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={6}>
                        <Controls.Autocomplete
                            displayField='lugarInicio'
                            displayField1='lugarFin'
                            error={errors.tramoId}
                            label='Numero de Tramo *'
                            name='tramoId'
                            onChange={handleInputChange}
                            options={tramos}
                            value={values.tramoId}
                        />

                        <Controls.Autocomplete
                            displayField='nombre'
                            error={errors.ubicacionId}
                            label='Ubicacion *'
                            name='ubicacionId'
                            onChange={handleInputChange}
                            options={ubicaciones}
                            value={values.ubicacionId}
                        />

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label='Tiempo estimado de llegada'
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} />}
                                value={values.eta}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label='Tiempo real de llegada'
                                onChange={handleChangeAta}
                                renderInput={(params) => <TextField {...params} />}
                                value={values.ata}
                            />
                        </LocalizationProvider>

                        <Controls.Input
                            error={errors.detalles}
                            label='Detalles'
                            name='detalles'
                            onChange={handleInputChange}
                            value={values.detalles}
                        />

                        <Controls.Select
                            error={errors.status}
                            label='Origen/Destino'
                            name='origenDestino'
                            onChange={handleInputChange}
                            options={origenDestino}
                            value={values.origenDestino}
                        />
                        <Controls.NumericInput
                            error={errors.ordenEntrega}
                            label='Orden De Entrega'
                            name='ordenEntrega'
                            onChange={handleInputChange}
                            value={values.ordenEntrega}
                        />

                        <Controls.Checkbox
                            label='EntregaTerminada'
                            name='entregaTerminada'
                            onChange={handleInputChange}
                            value={values.entregaTerminada}
                        />

                        <div>
                            <Controls.Button text='Guardar' type='submit' />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.title} variant='h6'>
                            Datos de la Ubicación
                        </Typography>
                        <Controls.Autocomplete
                            disableClearable
                            disabled
                            displayField='telefono'
                            label='Teléfono'
                            name='telefono'
                            onChange={handleInputChange}
                            options={ubicaciones}
                            readOnly
                            value={values.ubicacionId}
                        />
                        <Controls.Autocomplete
                            disableClearable
                            disabled
                            displayField='contacto'
                            label='Contacto'
                            name='contacto'
                            onChange={handleInputChange}
                            options={ubicaciones}
                            readOnly
                            value={values.ubicacionId}
                        />

                        <Controls.Autocomplete
                            disabled
                            displayField='direccion'
                            label='Dirección'
                            name='direccion'
                            onChange={handleInputChange}
                            options={ubicaciones}
                            value={values.ubicacionId}
                        />
                        <Controls.Autocomplete
                            disabled
                            displayField='url'
                            label='URL'
                            name='url'
                            onChange={handleInputChange}
                            options={ubicaciones}
                            value={values.ubicacionId}
                        />
                    </Grid>
                </Grid>
            </Form>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Falta Anexar archivo'
            >
                Para marcar esta entrega terminada, se necesita anexar archivo de comprobante de entrega
            </Popup>
        </>
    );
}
