/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Toolbar as MuiToolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PrintIcon from '@mui/icons-material/Print';

import { Controls } from '../../components/controls/Controls';
import { loadViaje, saveViaje, newViaje } from '../../redux/ducks/viajes';
import { loadMtto, saveMtto, newMtto } from '../../redux/ducks/mttos';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { setButton } from '../../redux/ducks/updateButton';
import { setButtonCasetas } from '../../redux/ducks/casetasButton';
import ConfirmDialog from '../../components/ConfirmDialog';
import http, { BASE_URL } from '../../http-common';
import { setIsDirtyMateriales } from '../../redux/ducks/dirtyMateriales';

export default function Toolbar({
    isDirty, setIsDirty, values, errors, handleInputChange, validate, setValues, removeField, setAllValues,
}) {
    const dispatch = useDispatch();
    const isDirtyMateriales = useSelector((state) => state.dirtyMateriales.isDirtyMateriales);
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });
    const buscaMtto = () => {
        if (isDirty) {
            setConfirmDialog({
                subtitle: '',
                title: 'Desea continuar sin guardar?',
                isOpen: true,
                onConfirm: () => {
                    setIsDirty(false);
                    dispatch(setIsDirtyMateriales(false));
                    dispatch(setButton(true, false));
                    dispatch(loadMtto(values.id));
                },
            });
        } else {
            setIsDirty(false);
            dispatch(setIsDirtyMateriales(false));
            dispatch(loadMtto(values.id));
        }
    };

    const [disableFolio, setDisableFolio] = useState(true);

    const save = async () => {
        let password = '';
        if (validate()) {
            if (values.status === 'APROBADO') {
                password = prompt('Password', '');
            }
            dispatch(saveMtto(values, password));
            setIsDirty(false);
        } else {
            dispatch(setSnackbar(true, 'error', 'Error al guardar, Faltan Datos'));
        }
    };

    const mttoNuevo = () => {
        dispatch(newMtto());
        setValues({});
    };

    useEffect(() => {
        if (isDirtyMateriales === false) {
            buscaMtto();
        }
    }, []);

    useEffect(() => {
        if (values.isDirty === undefined && isDirtyMateriales === false) {
            setIsDirty(false);
            dispatch(setIsDirtyMateriales(false));
        }
    }, [values]);

    const print = async () => {
        try {
            const response = await http.get(`/mtto/${values.id}/print`, {
                responseType: 'blob',
            });

            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        } catch (error) {
            return { error };
        }
        return '';
    };

    const aprobar = async () => {
        try {
            // eslint-disable-next-line no-alert
            const password = prompt('Password', '');
            const valuesClean = await removeField('isDirty');
            const result = await http.post(`/mtto?password=${password}`, valuesClean, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (result.status === 200) {
                if (result.data.status === 'APROBADO') {
                    setAllValues(result.data);
                    dispatch(setIsDirtyMateriales(false));
                    setIsDirty(false);
                } else {
                    dispatch(setSnackbar(true, 'error', result.data.status));
                }
            }
        // eslint-disable-next-line no-empty
        } catch {

        }
    };
    const updateButton = useSelector((state) => state.updateButton);

    return (
        <Grid item xs={12}>
            <MuiToolbar>
                <Grid container>

                    <Grid item xs={3}>
                        <Controls.Input
                            disabled={disableFolio}
                            error={errors.id}
                            label='Folio Mantenimiento'
                            margin='dense'
                            name='id'
                            onChange={handleInputChange}
                            value={values.id}
                            width='20%'
                        />
                    </Grid>
                    <Grid item>
                        <Controls.ActionButton
                            color='primary'
                            disabled={isDirty}
                            onClick={() => {
                                mttoNuevo();
                                setDisableFolio(false);
                            }}
                        >
                            <AddCircleIcon fontSize='large' />
                        </Controls.ActionButton>
                    </Grid>
                    <Grid item>
                        <Controls.ActionButton
                            color='primary'
                            onClick={() => {
                                buscaMtto();
                                setDisableFolio(true);
                            }}
                            // eslint-disable-next-line
                            
                        >
                            <SearchIcon fontSize='large' />
                        </Controls.ActionButton>
                    </Grid>
                    <Grid item>
                        <Controls.ActionButton
                            color={updateButton.apagado ? 'turnedOff' : 'primary'}
                            onClick={() => {
                                save();
                            }}
                            // eslint-disable-next-line
                            disabled = {!isDirty}

                        >
                            <SaveIcon fontSize='large' />
                        </Controls.ActionButton>
                    </Grid>
                    <Grid item>
                        <Controls.ActionButton
                            color='primary'
                            onClick={() => {
                                print();
                            }}
                        >
                            <PrintIcon fontSize='large' />
                        </Controls.ActionButton>
                    </Grid>
                    <Grid item>
                        {values.id === 0
                            ? <></>
                            : (values.status === 'PENDIENTE'
                                ? <Controls.Button onClick={aprobar} text='Aprobar' />
                                : values.status === 'APROBADO'
                                    ? (
                                        <Controls.Button
                                            disabled
                                            style={{
                                                background: '#90ee90',
                                            }}
                                            text='APROBADO'
                                        />
                                    )
                                    : null
                            )}
                    </Grid>
                </Grid>
            </MuiToolbar>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </Grid>
    );
}
