import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { setSnackbar } from '../redux/ducks/snackbar';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function CustomizedSnackbars() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
    const snackbarType = useSelector((state) => state.snackbar.snackbarType);
    const snackbarMessage = useSelector(
        (state) => state.snackbar.snackbarMessage,
    );
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSnackbar(false, snackbarType, snackbarMessage));
    };

    return (
        <div className={classes.root}>
            <Snackbar
                autoHideDuration={3000}
                onClose={handleClose}
                open={snackbarOpen}
            >
                <Alert
                    color={snackbarType}
                    elevation={6}
                    onClose={handleClose}
                    variant='filled'
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default CustomizedSnackbars;
