import {
    Grid, InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    withStyles,
    TableSortLabel,
    CircularProgress,
    Box,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { Search } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { loadViajes } from '../../redux/ducks/viajes';
import { dateAndTimeToMoment, filterOnAnyField } from '../../utils';
import { setIndex } from '../../redux/ducks/sideMenuIndex';

function calculaKilometros(viaje) {
    const currentGasolinas = viaje.combustible;

    let totalKilometros = 0.00;

    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            totalKilometros += g.kilometros;
        });
    }
    return totalKilometros;
}
function calculaCombustible(viaje) {
    const currentGasolinas = viaje.combustible;

    let totalGasolinas = 0.00;

    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            totalGasolinas += g.monto;
        });
    }
    return totalGasolinas;
}

function calculaRendimiento(viaje) {
    const currentGasolinas = viaje.combustible;

    let totalKilometros = 0.00;
    let totalLitros = 0.00;
    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            totalKilometros += g.kilometros;
            totalLitros += g.cantidad;
        });
        if (gas.length > 0) {
            return totalKilometros / totalLitros;
        }
    }
    return 0.0;
}

function calculaLitrosFaltantes(viaje) {
    const currentGasolinas = viaje.combustible;

    const rendimientoCalculado = calculaRendimiento(viaje);

    if (rendimientoCalculado >= viaje.unidad.rendimiento) {
        return 0;
    }

    let kilometros = 0.00;
    let cantidad = 0;

    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            kilometros += g.kilometros;
            cantidad += g.cantidad;
        });
    }

    return Math.round(
        ((cantidad - kilometros / viaje.unidad.rendimiento) + Number.EPSILON) * 100,
    ) / 100;
}

function calculaFaltantePesos(viaje) {
    const litros = calculaLitrosFaltantes(viaje);
    console.log(litros);
    if (litros <= 0) {
        return 0;
    }

    const currentGasolinas = viaje.combustible;
    let pu = 0.0;
    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            pu += g.pu;
        });
        console.log(pu);
        return litros * (pu / gas.length);
    }

    return 0.0;
}

function calculaCasetas(viaje) {
    const { casetas } = viaje;
    let totalCasetas = 0.0;
    if (casetas) {
        casetas.forEach((c) => {
            totalCasetas += c.importe;
        });
    }

    return totalCasetas;
}

function calculaFleteTramo(f) {
    let impuesto = 1;
    if (f.cliente && f.cliente.impuesto && f.cliente.impuesto > 0) {
        impuesto = 1 + (f.cliente.impuesto / 100.00);
    }
    return Math.round((f.flete * impuesto) * 100) / 100;
}

function calculateTotalVentas(viaje) {
    const { tramos } = viaje;
    const { otrosGastos } = viaje; // viene de la antigua funcion calculateExtras
    let totalFlete = 0.0;
    let totalVentas = 0.0;
    let totalOtrosGastos = 0.0; // viene de la antigua funcion calculateExtras
    if (tramos) {
        tramos.forEach((f) => {
            totalFlete += calculaFleteTramo(f);
        });
    }

    if (otrosGastos) { // viene de la antigua funcion calculateExtras
        otrosGastos.filter((f) => f.noLiquidacion === false).forEach((o) => {
            if (o.tipoPago.id === 'COBRO_AL_CLIENTE') {
                totalOtrosGastos += o.cantidad * 1.16;
            }
        });
    }

    totalVentas = totalFlete + totalOtrosGastos;

    return Math.round((totalVentas) * 100) / 100;
}

function calculateExtras(viaje) {
    const { otrosGastos } = viaje;
    let totalOtrosGastos = 0.0;

    if (otrosGastos) {
        otrosGastos.filter((f) => f.noLiquidacion === false).forEach((o) => {
            if (o.tipoPago.id === 'COBRO_AL_CLIENTE') {
                totalOtrosGastos += o.cantidad * 1.16;
            }
        });
    }
    return totalOtrosGastos;
}

const opcionesFacturas = [
    {
        id: '---',
        title: '---',
    },
    {
        id: 'Sin Factura',
        title: 'Sin Factura',
    },
    {
        id: 'Generada',
        title: 'Generada',
    },
    {
        id: 'Enviada',
        title: 'Enviada',
    },
    {
        id: 'Pagada',
        title: 'Pagada',
    },
];

function calculateTotalGastos(viaje) {
    const { otrosGastos } = viaje;
    let totalOtrosGastos = 0.0;

    if (otrosGastos) {
        otrosGastos.filter((f) => f.noLiquidacion === false).forEach((o) => {
            if (o.tipoPago.id === 'GENERALES' || o.tipoPago.id === 'PAGO_OPERADOR') {
                totalOtrosGastos += o.cantidad;
            }
        });
    }

    let pagoOperador = 0.0;
    viaje.tramos.forEach((t) => {
        pagoOperador += t.pagoOperador;
    });

    const currentGasolinas = viaje.combustible;
    let totalGasolinas = 0.00;

    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            totalGasolinas += g.monto;
        });
    }

    const { casetas } = viaje;
    let totalCasetas = 0.0;
    if (casetas) {
        casetas.forEach((c) => {
            totalCasetas += c.importe;
        });
    }

    return Math.round((totalOtrosGastos + pagoOperador + totalGasolinas + totalCasetas) * 100) / 100;
}

function calculaUtilidad(viaje) {
    const utilidad = calculateTotalVentas(viaje)
    - calculateTotalGastos(viaje);

    return Math.round((utilidad) * 100) / 100;
}

function calculaPorcentajeUtilidad(viaje) {
    const totalGanancias = calculateTotalVentas(viaje);
    const totalGastosUtil = calculateTotalGastos(viaje);
    let porcentajeUtilidad = 0;

    /* if (totalGanancias !== 0) {
        porcentajeUtilidad = 100 - ((totalGastosUtil * 100) / totalGanancias);
    } */

    if (totalGanancias !== 0) {
        porcentajeUtilidad = (calculaUtilidad(viaje) / totalGanancias) * 100;
    }

    return Math.round((porcentajeUtilidad) * 100) / 100;
}

function calculaGastos(viaje, tramo) {
    const gastosTramo = viaje.otrosGastos.filter((f) => f.tramoId === tramo.id && f.noLiquidacion === false);
    let totalOtrosGastos = 0.0;
    if (gastosTramo) {
        gastosTramo.forEach((o) => {
            if (o.tipoPago.id === 'GENERALES' || o.tipoPago.id === 'PAGO_OPERADOR') {
                totalOtrosGastos += o.cantidad;
            }
        });
    }
    return Math.round(totalOtrosGastos);
}

function calculaExtrasCliente(viaje, tramo) {
    const gastosTramo = viaje.otrosGastos.filter((f) => f.tramoId === tramo.id && f.noLiquidacion === false);
    let totalOtrosGastos = 0.0;
    if (gastosTramo) {
        gastosTramo.forEach((o) => {
            if (o.tipoPago.id === 'COBRO_AL_CLIENTE') {
                totalOtrosGastos += o.cantidad;
            }
        });
    }
    return Math.round((totalOtrosGastos * 1.16) * 100) / 100;
}

const headCells = [
    { id: '', label: '' },
    { id: 'id', label: 'Folio' },
    { id: 'status', label: 'Status' },
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'fechaFin', label: 'Fecha Fin' },
    { id: 'operador', label: 'Operador' },
    { id: 'unidad', label: 'Unidad' },
    { id: 'kilometros', label: 'Kilometros' },
    { id: 'gasolinas', label: 'Total de Combustible' },
    { id: 'rendimiento', label: 'Rendimiento' },
    { id: 'litros', label: 'Litros Faltantes' },
    { id: 'pesos', label: 'Faltante en Pesos' },
    { id: 'casetas', label: 'Total de Casetas' },
    { id: 'totalVentas', label: 'Total de Venta' },

    { id: 'totalGastos', label: 'Total de Gastos' },
    { id: 'utilidad', label: 'Utilidad del Viaje' },
    { id: 'porcentajeUtilidad', label: 'Porcentaje de Uitlidad' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

const headCellsTramo = [
    { id: 'cliente', label: 'Cliente' },
    { id: 'inicio', label: 'Inicio' },
    { id: 'fin', label: 'Fin' },
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'fechaFin', label: 'Fecha Fin' },
    { id: 'ordenDeCompra', label: 'Orden de Compra' },
    { id: 'kilometros', label: 'Kilometros' },
    { id: 'flete', label: 'Flete' },
    { id: 'fleteIva', label: 'Flete con Iva' },
    { id: 'extras', label: 'Extras Cliente' },
    { id: 'gastos', label: 'Gastos' },
    { id: 'pagoOp', label: 'Pago Operador' },
    { id: 'factura', label: 'Factura' },
    { id: 'statusPagoOperador', label: 'Status Pago Operador' },
    { id: 'fechaPago', label: 'Fecha Pago Operador' },
    { id: 'statusPagoTramo', label: 'Status Factura' },
];

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '450',
        },
        '& tbody tr:hover': {
        // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    sinFactura: {
        background: '#F5B7B1',
        lineHeight: 1,
    },
    facturaGenerada: {
        background: '#FCF3CF',
        lineHeight: 1,
    },
    facturaEnviada: {
        background: '#AED6F1',
        lineHeight: 1,
    },
    facturaPagada: {
        background: '#ABEBC6',
        lineHeight: 1,
    },
    sinTramos: {
        background: '#ffffff',
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    amarillo: { backgroundColor: '#f5f5dc' },

}));

function Row({ item, orderBy }) {
    const [open, setOpen] = React.useState(false);
    const [order, setOrder] = useState();
    const classes = useStyles();

    const getHeaderTramoStyle = (tramos) => {
        const c = classes.facturaPagada;
        const facturas = tramos.map((t) => t.factura);

        if (facturas.includes(null)) {
            const pagoConEfectivo = tramos.filter((t) => !t.factura && t.pagoEfectivo);
            if (tramos.length === pagoConEfectivo.length) {
                return classes.facturaPagada;
            }

            return classes.sinFactura;
        }
        const status = facturas.map((s) => s.status);

        if (status.includes('Generada')) {
            return classes.facturaGenerada;
        }
        if (status.includes('Enviada')) {
            return classes.facturaEnviada;
        }

        if (status.includes('Pagada')) {
            return classes.facturaPagada;
        }

        return classes.sinTramos;
    };
    const getBackgroundStyle = (tramo) => {
        if (!tramo.factura) {
            if (tramo.pagoEfectivo) {
                return classes.facturaPagada;
            }

            return classes.sinFactura;
        }
        switch (tramo.factura.status) {
        case 'Generada':
            return classes.facturaGenerada;
        case 'Enviada':
            return classes.facturaEnviada;
        case 'Pagada':
            return classes.facturaPagada;
        default:
            return classes.facturaPagada;
        }
    };
    return (
        <>
            <TableRow key={item.id} className={getHeaderTramoStyle(item.tramos)}>
                <TableCell>
                    <IconButton aria-label='expand item' onClick={() => setOpen(!open)} size='small'>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {item.id}
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>
                    <Moment date={item.fechaInicio} format='D MMM YYYY HH:mm' />
                </TableCell>
                <TableCell>
                    <Moment date={item.fechaFin} format='D MMM YYYY HH:mm' />
                </TableCell>
                <TableCell>
                    {item.operador?.nombre}
                    {' '}
                    {item.operador?.apellidos}
                    {' '}
                </TableCell>
                <TableCell>
                    {item.unidad.numeroEconomico}
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        thousandSeparator
                        value={calculaKilometros(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={calculaCombustible(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        thousandSeparator
                        value={calculaRendimiento(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        thousandSeparator
                        value={calculaLitrosFaltantes(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        thousandSeparator
                        value={calculaFaltantePesos(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={calculaCasetas(item)}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={calculateTotalVentas(item)}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={calculateTotalGastos(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={calculaUtilidad(item)}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='%'
                        thousandSeparator
                        value={calculaPorcentajeUtilidad(item)}
                    />
                </TableCell>
                <TableCell>
                    <Link to={`/viaje/${item.id}`}>
                        <Controls.ActionButton
                            color='primary'
                        >
                            <EditOutlinedIcon fontSize='small' />
                        </Controls.ActionButton>
                    </Link>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Tramos:
                            </Typography>
                            <Table aria-label='purchases' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headCellsTramo.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item.tramos.map((tramo) => (
                                        <TableRow
                                            key={tramo.id}
                                            className={getBackgroundStyle(tramo)}
                                        >
                                            <TableCell component='th' display='block' scope='row'>{tramo.cliente?.nombre}</TableCell>
                                            <TableCell>{tramo.lugarInicio}</TableCell>
                                            <TableCell>{tramo.lugarFin}</TableCell>
                                            <TableCell>
                                                <Moment date={tramo.fechaInicio} />
                                            </TableCell>
                                            <TableCell><Moment date={tramo.fechaFin} /></TableCell>
                                            <TableCell>{tramo.ordenDeCompra}</TableCell>

                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix=''
                                                    thousandSeparator
                                                    value={tramo.kilometros}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix='$'
                                                    thousandSeparator
                                                    value={tramo.flete}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix='$'
                                                    thousandSeparator
                                                    value={calculaFleteTramo(tramo)}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix='$'
                                                    thousandSeparator
                                                    value={calculaExtrasCliente(item, tramo)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    decimalScale={2}
                                                    displayType='text'
                                                    prefix='$'
                                                    thousandSeparator
                                                    value={calculaGastos(item, tramo)}
                                                />
                                            </TableCell>
                                            <TableCell>{tramo.pagoOperador}</TableCell>
                                            <TableCell>{tramo.factura ? tramo.factura.numeroFactura : ''}</TableCell>
                                            <TableCell>{tramo.statusPagoOperador}</TableCell>
                                            <TableCell><Moment date={tramo.fechaPago} /></TableCell>
                                            <TableCell>{tramo.factura ? tramo.factura.status : ''}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
export default function LiquidacionViajes() {
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const gasolinas = useSelector((state) => state.gasolinas.gasolinas);
    const casetas = useSelector((state) => state.casetasLiq.casetas);

    const [soloFechaInicio, setSoloFechaInicio] = useState(null);
    const [soloHoraInicio, setSoloHoraInicio] = useState(null);
    const [soloFechaFin, setSoloFechaFin] = useState(null);
    const [soloHoraFin, setSoloHoraFin] = useState(null);
    const [viajesLiq, setViajesLiq] = useState(null);
    const [currentCasetas, setCurrentCasetas] = useState(null);
    const [test, setTest] = useState(null);

    const handleInputFechaInicio = (e) => {
        setSoloFechaInicio(e.target.value);
    };
    const handleInputHoraInicio = (e) => {
        setSoloHoraInicio(e.target.value);
    };

    const handleInputFechaFin = (e) => {
        setSoloFechaFin(e.target.value);
    };
    const handleInputHoraFin = (e) => {
        setSoloHoraFin(e.target.value);
    };

    const [status, setStatus] = useState(opcionesFacturas[0]);
    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    };
    const [id, setId] = useState();
    const handleChangeFolio = (e) => {
        setId(e.target.value);
    };
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    async function calculateViajes() {
        setIsLoading(true);
        const fechaInicioConHora = dateAndTimeToMoment(
            soloFechaInicio,
            soloHoraInicio,
        );
        const fechaFinConHora = dateAndTimeToMoment(
            soloFechaFin,
            soloHoraFin,
        );

        let filters = '';

        if (id) {
            filters = `folioId=${id}`;
        }

        if (fechaInicioConHora) {
            filters = `${filters}&fechaInicial=${fechaInicioConHora.toISOString(true)}`;
        }

        if (status.id !== '---') {
            filters = `${filters}&statusFactura=${status.id}`;
        }

        if (fechaFinConHora) {
            filters = `${filters}&fechaFinal=${fechaFinConHora.toISOString(true)}`;
        }

        try {
            const response = await http.get(`/viajes/liquidacionViaje?${filters}`);
            if (response) {
                setViajesLiq(response.data);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage('Error al obtener los datos');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    }

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(viajesLiq, headCells, filterFn);
    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };

    const dispatch = useDispatch();

    const refreshData = () => {
        dispatch(loadViajes());
    };
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    const handleSortRequest = (cellId) => {
        const isAsc = orderBy === cellId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(cellId);
    };

    useEffect(() => {
        dispatch(setIndex(0, 'Liq'));
    }, [dispatch]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <Box component='span' displayPrint='none'>
                <PageHeader
                    displayPrint='none'
                    icon={<AccountBalanceWalletIcon fontSize='large' />}
                    subtitle=''
                    title='Liquidacion Viajes'
                />
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={3}>

                        <Grid
                            container
                            spacing={1}
                        >

                            <Grid item xs={9}>
                                <Controls.DatePicker
                                    label='Fecha de Inicio'
                                    name='fechaInicio'
                                    onChange={handleInputFechaInicio}
                                    value={soloFechaInicio}
                                />
                            </Grid>

                            <Grid item xs={9}>
                                <Controls.TimePicker
                                    label='Hora de inicio'
                                    name='horaInicio'
                                    onChange={handleInputHoraInicio}
                                    value={soloHoraInicio}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <Grid
                            // alignContent='space-around'
                            container
                            spacing={1}
                        >
                            <Grid item xs={9}>
                                <Controls.DatePicker
                                    label='Fecha de Termino'
                                    name='fechaTermino'
                                    onChange={handleInputFechaFin}
                                    value={soloFechaFin}
                                />
                            </Grid>

                            <Grid item xs={9}>
                                <Controls.TimePicker
                                    label='Hora de Termino'
                                    name='horaFin'
                                    onChange={handleInputHoraFin}
                                    value={soloHoraFin}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={2}>
                        <Grid
                            container
                            direction='column'
                            spacing={1}
                        >

                            <Grid item xs={12}>
                                <Controls.Input
                                    label='Folio'
                                    name='id'
                                    onChange={handleChangeFolio}
                                    value={id}
                                />
                            </Grid>
                            <Grid
                                container
                                direction='column'
                                paddingTop='.5em'
                                spacing={1}
                            >
                                <Grid item xs={12}>
                                    <Controls.Select
                                        label='Estatus Factura'
                                        name='status'
                                        onChange={handleChangeStatus}
                                        options={opcionesFacturas}
                                        style={{ minWidth: 200 }}
                                        value={status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={1}>
                        <Controls.Button
                            className={classes.refreshButton}
                            onClick={() => calculateViajes()}
                            startIcon={<RefreshIcon />}
                            text='Calcular'
                            variant='outlined'
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    justifyContent='center'
                    paddingTop='.5em'

                />

                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={12}>

                        <Grid
                            alignContent='space-around'
                            container
                            justifyContent='center'
                            spacing={1}
                        >

                            <Grid item xs={8}>
                                <Controls.Input
                                    className={classes.searchInput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='end'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label='Buscar'
                                    onChange={handleSearch}
                                    value={filter}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Box>
            <TblContainer component={Paper}>
                <TblHead>
                    <TableRow>
                        <TableCell />
                        {headCells.map((head) => (

                            <TableCell
                                key={head.id}
                                sortDirection={orderBy === head.id ? order : false}
                            >
                                {head.disableSorting ? (head.label) : (
                                    <TableSortLabel
                                        active={orderBy === head.id}
                                        direction={
                                            orderBy === head.id ? order : 'asc'
                                        }
                                        onClick={() => handleSortRequest(head.id)}
                                    >
                                        {head.label}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TblHead>
                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell align='center' colSpan={headCells.length}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', p: 6,
                                }}
                                >
                                    <CircularProgress />
                                    <Typography sx={{ mt: 2 }} variant='h6'>Cargando...</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        recordsAfterPagingAndSorting().map((item) => (
                            <Row key={item.id} item={item} />
                        )))}
                </TableBody>
            </TblContainer>
            <Box component='span' displayPrint='none'>
                <TblPagination />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                open={openSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
