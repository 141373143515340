import { DownloadForOfflineOutlined, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    InputAdornment, Paper,
    TableBody,
    TableCell,
    TableRow,
    Grid,
    CircularProgress,
    Box,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CsvBuilder } from 'filefy';
import moment from 'moment';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { loadViajes } from '../../redux/ducks/viajes';
import { filterOnAnyField } from '../../utils';
import http from '../../http-common';
import { loadMttoHistorial } from '../../redux/ducks/mttos';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    searchPorViaje: {
        width: '30%',
        marginLeft: 23,
    },
    newButton: {},
    refreshButton: {},
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '450',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    sinFactura: {
        background: '#F5B7B1',
        lineHeight: 1,
    },
    facturaGenerada: {
        background: '#FCF3CF',
        lineHeight: 1,
    },
    facturaEnviada: {
        background: '#AED6F1',
        lineHeight: 1,
    },
    facturaPagada: {
        background: '#ABEBC6',
        lineHeight: 1,
    },
    amarillo: { backgroundColor: '#f5f5dc' },

}));

const headCellsMtto = [
    { id: 'id', label: 'Folio Mttto' },
    { id: 'unidad.numeroEconomico', label: 'Unidad' },
    { id: 'mecanico', label: 'Mecanico' },
    { id: 'kilometraje', label: 'Kilometraje' },
    { id: 'fecha', label: 'Fecha' },
    { id: 'descripcionReparacion', label: 'Descripcion de Reparación' },
    { id: 'status', label: 'Estatus' },
    { id: 'accion', label: 'Acciones', disableSorting: true },
];

// const {handleListItemClick} = useContext(SideMenuContext);

export default function HistorialMtto() {
    const classes = useStyles();

    const getBackgroundStyle = (mtto) => {
        switch (mtto.status) {
        case 'PENDIENTE':
            return classes.sinFactura;
        case 'Enviada':
            return classes.facturaEnviada;
        case 'APROBADO':
            return classes.facturaPagada;
        default:
            return classes.facturaPagada;
        }
    };
    const [userInfo, setUserInfo] = useState();
    const { authState, oktaAuth } = useOktaAuth();
    const [canViewViaje, setCanViewViaje] = useState(false);
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((ui) => {
                setUserInfo(ui);
                if (ui.groups.includes('Trafico') || ui.groups.includes('Administradores')) {
                    setCanViewViaje(true);
                }
            });
        }
    }, [authState, oktaAuth]);

    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const mttos = useSelector((state) => state.mttos.mttos);
    const isLoading = useSelector((state) => state.mttos.isLoading);
    const [tramos, setTramos] = useState([]);
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(mttos, headCellsMtto, filterFn);

    const [filter, setFilter] = useState();
    const [filterPorFolio, setFilterPorFolio] = useState();
    const [filterPorUnidad, setFilterPorUnidad] = useState();
    const [fechaInicial, setFechaInicial] = useState();
    const [fechaFinal, setFechaFinal] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                let filteredItems = items.map((m) => m);
                if (target?.value) {
                    filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                }

                if (fechaInicial) {
                    filteredItems = filteredItems.filter((f) => {
                        const fechaTramo = moment(f.fechaInicio);
                        if (fechaTramo.isSameOrAfter(fechaInicial, 'day')) {
                            return true;
                        }

                        return false;
                    });
                }
                if (fechaFinal) {
                    filteredItems = filteredItems.filter((f) => {
                        const fechaTramo = moment(f.fechaInicio);
                        if (fechaTramo.isSameOrBefore(fechaFinal, 'day')) {
                            return true;
                        }
                        return false;
                    });
                }
                return filteredItems;
            },
        });
    };

    const buscarPorFolioChange = (e) => {
        const { target } = e;
        setFilterPorFolio(target.value);
        setFilterPorUnidad(target.value);
    };

    const buscarPorFolio = () => {
        setFilterFn({
            fn: (items) => {
                if (filterPorFolio === '') {
                    return items;
                }
                // eslint-disable-next-line radix
                const filteredItems = items.filter((x) => x.id === parseInt(filterPorFolio));
                return filteredItems;
            },
        });
    };

    const buscarPorUnidad = () => {
        setFilterFn({
            fn: (items) => {
                if (filterPorUnidad === '') {
                    return items;
                }
                // eslint-disable-next-line radix
                const filteredItems = items.filter((x) => x.unidad.numeroEconomico === filterPorUnidad);
                return filteredItems;
            },
        });
    };

    const mostrarSoloPendientes = () => {
        setFilterFn({
            fn: (items) => {
                // const filteredItems = items.filter((x) => (x.factura !== null ? x.statusFactura !== 'Pagada' : x.factura === null));
                const filteredItems = items.filter((x) => x.factura === null);
                const tramosTotal = filteredItems.filter((x) => !x.pagoEfectivo);

                tramosTotal.sort((a, b) => ((a.fechaInicio > b.fechaInicio) ? 1 : -1));
                return tramosTotal;
            },

        });
    };

    const dispatch = useDispatch();

    const refreshData = async () => {
        dispatch(loadMttoHistorial());
    };

    useEffect(() => {
        dispatch(loadMttoHistorial());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(2, 'Taller'));
    }, [dispatch]);

    const handleFechaInicialChange = (v) => {
        setFechaInicial(v.target.value);
    };

    const handleFechaFinalChange = (v) => {
        setFechaFinal(v.target.value);
    };

    useEffect(() => {
        handleSearch({
            target: {
                value: filter,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fechaFinal, fechaInicial]);

    const download = () => {
        const columnas = ['Folio', 'Unidad', 'Mecanico', 'Kilometraje', 'Fecha', 'Descripcion de Reparación', 'Estatus'];
        const rows = recordsAfterPagingAndSorting().map((mtto) => ([
            mtto.id,
            mtto.unidad.numeroEconomico,
            mtto.mecanico,
            mtto.kilometraje,
            moment(mtto.fecha).format('yyyy-MM-D'),
            mtto.descripcionRep,
            mtto.status,
        ]));
        const builder = new CsvBuilder('mantenimientos.csv');
        builder
            .setDelimeter(',')
            .setColumns(columnas)
            .addRows(rows)
            .exportFile();
    };

    return (
        <>
            <PageHeader
                icon={<ChecklistIcon fontSize='large' />}
                subtitle=''
                title='Historial de Mantenimientos'
            />
            <Paper className={classes.pageContent}>
                <Controls.Input
                    className={classes.searchPorViaje}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='end'>
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    label='Folio'
                    onChange={buscarPorFolioChange}
                    style={{ marginBottom: '10px' }}
                    value={filterPorFolio}
                />
                <Controls.Button
                    className={classes.refreshButton}
                    onClick={() => buscarPorFolio()}
                    text='Buscar por Folio'
                    variant='outlined'
                />
                <Controls.Button
                    className={classes.refreshButton}
                    onClick={() => buscarPorUnidad()}
                    text='Buscar por Unidad'
                    variant='outlined'
                />

                <Grid container>
                    <Grid container style={{ marginBottom: '10px' }}>
                        <Grid item xs={6}>
                            <Controls.Input
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='end'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Busqueda General'
                                onChange={handleSearch}
                                value={filter}
                            />
                        </Grid>

                    </Grid>
                    <Controls.Button
                        className={classes.refreshButton}
                        onClick={() => refreshData()}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                    />
                    <Link style={{ textDecoration: 'none' }} to='/Mantenimiento'>
                        <Controls.Button
                            className={classes.newButton}
                            startIcon={<AddIcon />}
                            text='Nuevo Mantenimiento'
                            variant='outlined'
                        />
                    </Link>
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            download();
                        }}
                        startIcon={<DownloadForOfflineOutlined />}
                        text='Descargar'
                        variant='outlined'
                    />
                </Grid>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell align='center' colSpan={headCellsMtto.length}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', p: 6,
                                    }}
                                    >
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }} variant='h6'>Cargando...</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            recordsAfterPagingAndSorting().map((mtto) => (
                                <TableRow key={mtto.id} className={getBackgroundStyle(mtto)}>
                                    <TableCell>{mtto.id}</TableCell>
                                    <TableCell>{mtto.unidad.numeroEconomico}</TableCell>
                                    <TableCell>
                                        {
                                            canViewViaje && mtto.mecanico
                                        }
                                    </TableCell>
                                    <TableCell>{mtto.kilometraje}</TableCell>
                                    <TableCell><Moment date={mtto.fecha} /></TableCell>
                                    <TableCell>{mtto.descripcionRep}</TableCell>
                                    <TableCell>
                                        {mtto.status}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            canViewViaje
                                        && (
                                            <Link onClick={() => { dispatch(setIndex(0, 'Taller')); }} to={`/Mantenimiento/${mtto.id}`}>
                                                <Controls.ActionButton
                                                    color='primary'
                                                >
                                                    <EditOutlinedIcon fontSize='small' />
                                                </Controls.ActionButton>
                                            </Link>
                                        )
                                        }
                                    </TableCell>
                                </TableRow>
                            )))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
        </>
    );
}
