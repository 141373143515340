import moment from 'moment';

// Action Types
export const LOAD_REGISTRO_MATERIALES = 'LOAD_REGISTRO_MATERIALES';
export const SET_REGISTRO_MATERIALES = 'SET_REGISTRO_MATERIALES';
export const SAVE_REGISTRO_MATERIAL = 'SAVE_REGISTRO_MATERIAL';
export const SAVED_REGISTRO_MATERIAL = 'SAVED_REGISTRO_MATERIAL';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ERROR_SAVING_FLAG = 'SET_SAVING_FLAG';
// Action Creators
export const loadRegistroMateriales = () => ({
    type: LOAD_REGISTRO_MATERIALES,
});

export const setRegistroMateriales = (materiales) => ({
    type: SET_REGISTRO_MATERIALES,
    materiales,
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const setErrorSavingFlag = (errorSavingFlag) => ({
    type: SET_ERROR_SAVING_FLAG,
    errorSavingFlag,
});

export function saveRegistroMaterial(material, password) {
    const materialCopy = {
        ...material,
    };

    if (material.fechaCompra !== null) {
        const fechaCompra = moment(material.fechaCompra).format('YYYY-MM-DD');
        const fechaPago = moment(material.fechaPago).format('YYYY-MM-DD');
        const pagarAntesDe = moment(material.pagarAntesDe).format('YYYY-MM-DD');
        materialCopy.fechaCompra = moment.utc(`${fechaCompra} 00:00:00`);
        materialCopy.fechaPago = moment.utc(`${fechaPago} 00:00:00`);
        materialCopy.pagarAntesDe = moment.utc(`${pagarAntesDe} 00:00:00`);
    }
    const { isDirty, ...materialCopyRest } = materialCopy;

    return {
        type: SAVE_REGISTRO_MATERIAL,
        material: materialCopyRest,
        password,
    };
}

export const savedRegistroMaterial = (material) => ({
    type: SAVED_REGISTRO_MATERIAL,
    material,
});

// Initial State
const initialState = {
    materiales: [],
    isLoading: false,
    errorSavingFlag: 0,
};

// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
    case SET_REGISTRO_MATERIALES:
        return {
            ...state,
            materiales: action.materiales,
        };
    case SAVED_REGISTRO_MATERIAL:
        return {
            ...state,
            materiales: state.materiales
                .filter((existingMaterial) => existingMaterial.id !== action.material.id) // Remove existing material with the same id
                .concat(action.material), // Add the new material
        };
    case SET_IS_LOADING:
        return {
            ...state,
            isLoading: action.isLoading,
        };
    case SET_ERROR_SAVING_FLAG:
        return {
            ...state,
            errorSavingFlag: action.errorSavingFlag,
        };
    default:
        return state;
    }
};
