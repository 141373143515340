import { call, put } from 'redux-saga/effects';
import {
    setRegistroMateriales, savedRegistroMaterial, setIsLoading, setErrorSavingFlag,
} from '../../ducks/registroMateriales';
import { requestLoadRegistroMateriales, requestSaveRegistroMaterial } from '../requests/registroMateriales';

export function* handleLoadRegistroMateriales() {
    yield put(setIsLoading(true));
    try {
        const response = yield call(requestLoadRegistroMateriales);
        const { data } = response;
        yield put(setRegistroMateriales(data));
    } catch (error) {
        console.log(error);
    } finally {
        yield put(setIsLoading(false));
    }
}

export function* handleSaveRegistroMaterial(action) {
    try {
        const response = yield call(requestSaveRegistroMaterial, action.material, action.password);
        const { data } = response;

        if (response.status === 200) {
            yield put(setErrorSavingFlag(2));
        }

        yield put(savedRegistroMaterial(data));
    } catch (error) {
        if (error.response && error.response.status === 403) {
            throw error;
        }
        console.log(error);
    }
}
