const tipoDeMtto = [
    { id: 'CORRECTIVO', text: 'Correctivo' },
    { id: 'PREVENTIVO', text: 'Preventivo' },
];

export default function getTipoDeMtto(id) {
    if (id) {
        return tipoDeMtto.find((item) => item.id === id) || { text: '', id: 0 };
    }
    return tipoDeMtto;
}
