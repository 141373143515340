const statusCompra = [
    { id: 'PENDIENTE', text: 'Pendiente' },
    { id: 'PAGADO', text: 'Pagado' },
    { id: 'CANCELADA', text: 'Cancelada' },
];

export default function getStatusCompra(id) {
    if (id) {
        return statusCompra.find((item) => item.id === id) || null;
    }
    return statusCompra;
}
