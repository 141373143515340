import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
    CircularProgress,
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForeverOutlined, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadForOfflineOutlined from '@mui/icons-material/DownloadForOfflineOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InventoryIcon from '@mui/icons-material/Inventory';
import Moment from 'react-moment';
import CalculateIcon from '@mui/icons-material/Calculate';
import NumberFormat from 'react-number-format';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { CsvBuilder } from 'filefy';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import { loadRegistroMateriales, saveRegistroMaterial, setErrorSavingFlag } from '../../redux/ducks/registroMateriales'; // Update import
import RegistroMaterialForm from './RegistroMaterialForm'; // Create this form component
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { filterOnAnyField } from '../../utils';
import ConfirmDialog from '../../components/ConfirmDialog';
import http from '../../http-common';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'fechaCompra', label: 'Fecha de Adquisicion' },
    { id: 'tipoGasto', label: 'Tipo de Compra' },
    { id: 'concepto', label: 'Concepto' },
    { id: 'proveedor', label: 'Proveedor' },
    { id: 'indicadorFactura', label: 'Indicador Factura' },
    { id: 'facturaProveedor', label: 'Numero de Factura' },
    { id: 'precioUnitario', label: 'Total' },
    { id: 'banco', label: 'Forma de Pago' },
    { id: 'pagarAntesDe', label: 'Fecha limite de credito' },
    { id: 'fechaPago', label: 'Fecha de Pago' },
    { id: 'numeroParte', label: 'Numero de Parte' },
    { id: 'acciones', label: 'Acciones', disableSorting: true },
];

export default function RegistroMateriales() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();
    const [aplicarFiltroButton, setAplicarFiltroButton] = useState(false);

    const materiales = useSelector((state) => state.registroMateriales.materiales);
    const isLoading = useSelector((state) => state.registroMateriales.isLoading); // Update selector

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tempItems, setTempItems] = useState([]);

    const [filterOficina, setFilterOficina] = useState(false);
    const [filterUnidad, setFilterUnidad] = useState(false);

    const handleFilter = () => {
        if (startDate === '' || endDate === '') {
            // eslint-disable-next-line
            alert('Para aplicar el filtro necesitas especificar fecha INICIO y fecha FIN');
            return;
        }
        const filteredItems = materiales.filter((item) => { // Update to use materiales
            const fechaCompra = new Date(item.fechaCompra);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return fechaCompra >= start && fechaCompra <= end;
        });
        setTempItems(filteredItems); // Set tempItems to the filtered items
        setFilterFn({
            fn: (items) => filteredItems, // Update filter function to return filtered items
        });
        setAplicarFiltroButton(true);
    };

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(materiales, headCells, filterFn);

    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (tempItems.length > 0) { // Check if tempItems is not empty
                    const filteredItems = filterOnAnyField(tempItems, target.value.toLowerCase());
                    return filteredItems; // Filter using tempItems
                }
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems; // Filter using original items
            },
        });
    };

    const dispatch = useDispatch();

    const addOrEdit = (material, remainOpen, password) => {
        dispatch(saveRegistroMaterial(material, password)); // Update action

        setOpenPopup(remainOpen);
    };

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    useEffect(() => {
        dispatch(loadRegistroMateriales());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(1, 'Taller')); // Update index
    }, [dispatch]);

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteMaterial = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar el material?',
            isOpen: true,
            onConfirm: async () => {
                // eslint-disable-next-line
                const password = prompt("Ingrese password para Borrar");
                await http.delete(`/registroMat/${item.id}?password=${password}`); // Update endpoint
                dispatch(loadRegistroMateriales()); // Update action
            },
        });
    };

    const getRowBackgroundColor = (status) => {
        if (status === 'PENDIENTE') return '#F5B7B1';
        if (status === 'PAGADO') return '#ABEBC6';
        return 'transparent'; // Default color
    };

    const clearFilter = () => {
        setTempItems([]);
        setStartDate('');
        setEndDate('');
        setAplicarFiltroButton(false);
        setFilterOficina(false);
        setFilterUnidad(false);
        setFilterFn({
            fn: (items) => items, // Reset to show all items
        });
    };

    const download = () => {
        const columnas = ['Fecha de Adquisicion', 'Tipo de Compra', 'Concepto', 'Proveedor', 'Precio Unitario', 'Indicador Factura', 'Numero de Factura', 'Total', 'Forma de Pago', 'Fecha limite de credito', 'Fecha de Pago', 'Numero de Parte'];
        const rows = recordsAfterPagingAndSorting().map((material) => ([
            material.fechaCompra,
            /* eslint-disable-next-line */
            material.tipoGasto === 'UNIDAD' ? (material.unidad?.numeroEconomico ? material.unidad?.numeroEconomico : 'VARIAS Unidades') : 'Oficina',
            material.concepto,
            material.proveedor,
            material.indicadorFactura,
            material.facturaProveedor,
            material.precioUnitario,
            material.banco,
            material.pagarAntesDe,
            material.fechaPago,
            material.numeroParte,
        ]));
        const builder = new CsvBuilder('materiales.csv');
        builder
            .setDelimeter(',')
            .setColumns(columnas)
            .addRows(rows)
            .exportFile();
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === 'filterOficina') {
            setFilterOficina(checked);
        } else if (name === 'filterUnidad') {
            setFilterUnidad(checked);
        }
    };

    useEffect(() => {
        setFilterFn({
            fn: (items) => {
                if (filterOficina && filterUnidad) {
                    return items; // Show all items
                }
                if (filterOficina) {
                    return items.filter((item) => item.tipoGasto === 'OFICINA');
                }
                if (filterUnidad) {
                    return items.filter((item) => item.tipoGasto === 'UNIDAD');
                }
                return items; // Show all items if none are checked
            },
        });
    }, [filterOficina, filterUnidad]);

    return (
        <>
            <PageHeader
                icon={<InventoryIcon fontSize='large' />}
                subtitle=''
                title='Registro de Compras de Materiales y Consumibles'
            />
            <Paper className={classes.filterPaper}>
                <Toolbar>
                    <Controls.DatePicker
                        label='Fecha de Compra INICIO'
                        name='fechaCompraInicio'
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                    />
                    <Controls.DatePicker
                        label='Fecha de Compra FIN'
                        name='fechaCompraFin'
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                    />
                    <Controls.Button
                        className={classes.searchInput}
                        disabled={aplicarFiltroButton}
                        onClick={handleFilter}
                        startIcon={<CalculateIcon />}
                        style={{ marginLeft: '10px' }}
                        text='Aplicar Filtro'
                    />
                    <Controls.Button
                        className={classes.searchInput}
                        onClick={clearFilter}
                        startIcon={<NotInterestedIcon />}
                        style={{ marginLeft: '10px' }}
                        text='Limpiar Filtro'
                    />
                </Toolbar>
            </Paper>

            <Paper className={classes.filterPaper} style={{ marginTop: '15px' }}>
                <Grid style={{ marginLeft: '30px' }}>
                    <Typography variant='h6'>Filtro Rapido</Typography>
                    <FormGroup row>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={filterOficina}
                                    name='filterOficina'
                                    onChange={handleCheckboxChange}
                                />
                            )}
                            label='Oficina'
                        />
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={filterUnidad}
                                    name='filterUnidad'
                                    onChange={handleCheckboxChange}
                                />
                            )}
                            label='Unidad'
                        />
                    </FormGroup>
                </Grid>
            </Paper>

            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        onClick={() => {
                            dispatch(loadRegistroMateriales());
                        }}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            dispatch(setErrorSavingFlag(0));
                            setRecordForEdit(null);
                            setOpenPopup(true);
                        }}
                        startIcon={<AddIcon />}
                        style={{ marginLeft: '10px', width: '300px' }}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            download();
                        }}
                        startIcon={<DownloadForOfflineOutlined />}
                        style={{ marginLeft: '10px' }}
                        text='Descargar'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell align='center' colSpan={headCells.length}>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', alignItems: 'center', p: 6,
                                    }}
                                    >
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }} variant='h6'>Cargando...</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                            : (recordsAfterPagingAndSorting().map((item) => (
                                <TableRow
                                    key={item.id}
                                    style={{ backgroundColor: getRowBackgroundColor(item.statusCompra) }}
                                >
                                    <TableCell>
                                        <Moment date={item.fechaCompra} />
                                    </TableCell>

                                    <TableCell style={{ minWidth: '150px' }}>
                                        {/* eslint-disable-next-line */}
                                        {item.tipoGasto === 'UNIDAD'
                                            ? (item.unidad?.numeroEconomico
                                                ? item.unidad?.numeroEconomico
                                                : 'VARIAS Unidades')
                                            : 'Oficina'}
                                    </TableCell>
                                    <TableCell>{item.concepto}</TableCell>
                                    <TableCell>{item.proveedor}</TableCell>
                                    <TableCell>{item.indicadorFactura ? 'SI' : 'NO'}</TableCell>
                                    <TableCell>{item.facturaProveedor}</TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            decimalScale={2}
                                            displayType='text'
                                            prefix='$'
                                            thousandSeparator
                                            value={item.precioUnitario}
                                        />
                                    </TableCell>
                                    {/* eslint-disable-next-line */}
                                    <TableCell>{item.banco === 'EFECTIVO' ? 'Efectivo' : item.banco === 'TRANSFERENCIA' ? 'Transferencia' : item.banco === 'TARJETA_CREDITO' ? 'Tarjeta de Credito' : null}</TableCell>
                                    <TableCell>
                                        <Moment date={item.pagarAntesDe} />
                                    </TableCell>
                                    <TableCell>
                                        <Moment date={item.fechaPago} />
                                    </TableCell>
                                    <TableCell>{item.numeroParte}</TableCell>

                                    <TableCell>
                                        <Controls.ActionButton
                                            color='primary'
                                            onClick={() => {
                                                dispatch(setErrorSavingFlag(0));
                                                openInPopup(item);
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize='small' />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            color='primary'
                                            onClick={() => {
                                                deleteMaterial(item);
                                            }}
                                        >
                                            <DeleteForeverOutlined fontSize='small' />
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>
                            )))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Material'
            >
                <RegistroMaterialForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                    setOpenPopup={setOpenPopup}
                />
            </Popup>

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}

            />
        </>
    );
}
