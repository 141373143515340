import http from '../../../http-common';

export function requestLoadRegistroMateriales() {
    return http.get('/registroMat');
}

export function requestSaveRegistroMaterial(material, password) {
    if (password) {
        return http.post(`/registroMat?password=${password}`, material);
    }
    return http.post('/registroMat', material);
}
