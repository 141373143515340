/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Prompt, useParams, useHistory, useLocation,
} from 'react-router-dom';
import { Form, FormNoMargin, useForm } from '../../components/useForm';
import Tramos from '../Viaje/Tramos';
import Generales from '../Viaje/Generales';
import ConfirmDialog from '../../components/ConfirmDialog';
import TopMantenimiento from './TopMantenimiento';
import ToolbarMantenimiento from './ToolbarMantenimiento';
import Gastos from '../Viaje/Gastos';
import Archivos from '../Viaje/Archivos';
import { Controls } from '../../components/controls/Controls';
import Combustible from '../Viaje/Combustible';
import Casetas from '../Viaje/Casetas';
import { loadViaje, updateViaje } from '../../redux/ducks/viajes';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { setButton } from '../../redux/ducks/updateButton';
import { setButtonCasetas } from '../../redux/ducks/casetasButton';
import Monitoreo from '../Viaje/Monitoreo';
import UbicacionesViaje from '../Viaje/UbicacionesViaje';
import Materiales from './Materiales';
import { loadMtto } from '../../redux/ducks/mttos';
import { setIsDirtyMateriales } from '../../redux/ducks/dirtyMateriales';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Mantenimiento(props) {
    const { mttoId } = useParams();
    const location2 = useLocation();
    const history = useHistory();

    const [isDirty, setIsDirty] = useState(false);
    const dispatch = useDispatch();
    const isDirtyMateriales = useSelector((state) => state.dirtyMateriales.isDirtyMateriales);
    const selectedMtto = useSelector((state) => state.mttos.selectedMtto);
    const updateButton = useSelector((state) => state.updateButton);
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    useEffect(() => {
        // Function to handle navigation away
        const handleNavigationAway = (location) => {
            const mttoIdString = `/Mantenimiento/${mttoId}`;
            // Check if the new location is different from the current one
            if (location.pathname !== '/Mantenimiento' || location.pathname !== mttoIdString) {
                // Set isDirtyMateriales to false when navigating away
                dispatch(setIsDirtyMateriales(false));
            }
        };

        // Listen for changes in the history
        const unlisten = history.listen(handleNavigationAway);

        // Cleanup listener on component unmount
        return () => {
            unlisten();
        };
    }, [dispatch, history, mttoId]);

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ('operador' in fieldValues) {
            temp.operador = fieldValues.operador ? '' : 'Campo Requerido';
        }
        if ('fecha' in fieldValues) {
            temp.fecha = fieldValues.fecha ? '' : 'Campo Requerido';
        }
        if ('mecanico' in fieldValues) {
            temp.mecanico = fieldValues.mecanico ? '' : 'Campo Requerido';
        }
        if ('unidad' in fieldValues) {
            temp.unidad = fieldValues.unidad ? '' : 'Campo Requerido';
        }
        if ('tipo' in fieldValues) {
            temp.tipo = fieldValues.tipo ? '' : 'Campo Requerido';
        }
        if ('kilometraje' in fieldValues) {
            temp.kilometraje = fieldValues.kilometraje ? '' : 'Campo Requerido';
        }
        if ('sintomas' in fieldValues) {
            temp.sintomas = fieldValues.sintomas ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    };
    const {
        values, setValues, errors, setErrors, handleInputChange, resetForm, handleInputChangeAutocomplete, removeField, setAllValues,
    // eslint-disable-next-line no-use-before-define
    } = useForm(selectedMtto, true, validate);

    useEffect(() => {
        setValues(selectedMtto);
    }, [selectedMtto, setValues]);

    // useEffect(() => {
    //   dispatch(updateMtto(values));
    // }, [values, dispatch]);

    const [currentTab, setCurrentTab] = useState(0);
    const { addOrEdit, recordForEdit } = props;

    useEffect(() => {
        if (isDirtyMateriales === true) {
            setIsDirty(true);
        }
        if (mttoId && (isDirty === false && isDirtyMateriales === false)) {
            dispatch(loadMtto(mttoId));
            dispatch(setIsDirtyMateriales(false));
            setIsDirty(false);
        }
        // eslint-disable-next-line
    }, [mttoId, dispatch, isDirtyMateriales]);

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
    }, [recordForEdit, setValues]);

    const handleChangeIndex = (index, value) => {
        setCurrentTab(value);
    };

    useEffect(() => {
        dispatch(setIndex(0, 'Taller'));
        dispatch(setButton(true, false));
        dispatch(setButtonCasetas(false));
    }, [dispatch]);

    const classes = useStyles();
    return (
        <>
            <Prompt
                message={`No has guardado los cambios:
            1 - 'OK' para continuar SIN guardar los cambios.
            2 - 'Cancel' para regresar a la pagina de Mantenimiento para que puedas guardarlos`}
                when={isDirty}
            />
            <FormNoMargin>
                <div className={classes.root}>
                    <Grid container>
                        <ToolbarMantenimiento
                            errors={errors}
                            handleInputChange={handleInputChange}
                            isDirty={isDirty}
                            removeField={removeField}
                            setAllValues={setAllValues}
                            setIsDirty={setIsDirty}
                            setValues={setValues}
                            validate={validate}
                            values={values}
                        />
                        <TopMantenimiento
                            errors={errors}
                            handleInputChange={handleInputChange}
                            handleInputChangeAutocomplete={handleInputChangeAutocomplete}
                            setIsDirty={setIsDirty}
                            values={values}
                        />
                        <Grid container>
                            <Tabs
                                aria-label='full width tabs example'
                                indicatorColor='primary'
                                onChange={handleChangeIndex}
                                textColor='primary'
                                value={currentTab}
                                variant='fullWidth'
                            >
                                <Tab label='Materiales' />
                            </Tabs>
                        </Grid>

                        <Controls.TabPanel index={0} value={currentTab}>
                            <Materiales
                                errors={errors}
                                handleInputChange={handleInputChange}
                                setValues={setValues}
                                values={values}
                            />
                        </Controls.TabPanel>

                    </Grid>
                </div>
            </FormNoMargin>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
