import http from '../../../http-common';

export function requestLoadMtto(id) {
    return http.get(`/mtto/${id}`);
}

export function requestSaveMtto(mtto, password) {
    return http.post(`/mtto?password=${password}`, mtto, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function requestLoadMttoHistorial() {
    return http.get('/mtto/all');
}
